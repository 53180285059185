import React, { useEffect, useState } from 'react'
import TableGrid from '../TableGrid/TableGrid'
import { Capitalize } from '../GlobalFunction/globalFunction';
import { Tooltip } from 'primereact/tooltip';
import { SplitButton } from 'primereact/splitbutton';
import "./Favorite.scss"
import InputTypeText from '../InputTypeText/InputTypeText';
import { Post } from '../../commanAPI/commanAPI';
import noimage from '../../assets/Img/noimage.svg';
import { FilterMatchMode } from 'primereact/api';
import SnackbarNotification from '../Snackbar/SnackbarNotification';
import Loader from '../Loader/loader';
import Nodataavailable from '../Nodataavailable/Nodataavailable';
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Image } from 'primereact/image';

function Favorite() {
    // User
    const userData = JSON.parse(localStorage.getItem("userData"));

    // Loader
    const [loader, setLoader] = useState(false);

    // SankBar
    const [sankbar, setSankbar] = useState(false);
    const [sankbarText, setSankbarText] = useState("");
    const [sankbarColor, setSankbarColor] = useState("");

    // Grid and List view
    const [GridandListView, setGridandListView] = useState(false);

    // Favorite
    const [favoriteArray, setFavoriteArray] = useState([]);
    const [favoritecolumns, setFavoritecolumns] = useState([]);

    // Filter 
    const [FilterValue, setFilterValue] = useState('');
    const [filtersData, setFiltersData] = useState({});
    const [filtersKey] = useState([]);

    var oneTime = false;
    useEffect(() => {
        if (!oneTime) {
            setLoader(true);
            favorite();
            oneTime = true;
        }
    }, []);

    const favorite = () => {
        Post('FavouriteMast/TypeWiseFill', { ITypes: "READYSTOCK", UserId: userData.UserId }).then((res) => {
            if (res) {
                if (res.status === 1) {
                    if (res.UserData.length > 0) {
                        setFavoriteArray(res.UserData)
                    } else {
                        setFavoriteArray([])
                    }
                    favoriteDataCode("Favorite");
                } else {
                    setLoader(false);
                    setSankbar(true);
                    setSankbarText(res.message);
                    setSankbarColor("error");
                }
            }
        });
    };

    const favoriteDataCode = (code) => {
        Post("ViewPara/GetFormWise", { VIEWNAME: code }).then((res) => {
            if (res) {
                if (res.status === 1) {
                    if (res.UserData.length > 0) {
                        setFavoritecolumns(res.UserData);
                        const output = { global: { value: null, matchMode: FilterMatchMode.CONTAINS }, };
                        res.UserData.forEach((item) => {
                            const fieldName = item.field;
                            if (!item.hide) {
                                output[fieldName] = { value: null, matchMode: FilterMatchMode.STARTS_WITH };
                                filtersKey.push(fieldName);
                            }
                        });
                        setFiltersData(output);
                        setLoader(false);
                    } else {
                        setLoader(false);
                        setSankbar(true);
                        setSankbarText(res.message);
                        setSankbarColor("warning");
                    }
                } else {
                    setLoader(false);
                    setSankbar(true);
                    setSankbarText(res.message);
                    setSankbarColor("error");
                }
            }
        });
    };

    const favoriteDelete = (data) => {
        Post('FavouriteMast/Save', { ItemId: data.ReadyID, UserId: userData.UserId, ITypes: "READYSTOCK" }).then((res) => {
            if (res) {
                if (res.status === 1) {
                    favorite();
                    setSankbar(true);
                    setSankbarText(res.message);
                    setSankbarColor("success");
                } else {
                    setLoader(false);
                    setSankbar(true);
                    setSankbarText(res.message);
                    setSankbarColor("error");
                }
            }
        });
    }

    const items = (data) => ([
        // {
        //     label: "Remove",
        //     icon: <svg className="icon" aria-hidden="true"><use xlinkHref="#update"></use></svg>,
        //     command: () => {
        //         setLoader(true);
        //         favoriteDelete(data);
        //     },
        // },

        // {

        //     label: ` ${isItemInCart(data) ? "Remove To Cart" : "Add To Cart"} `,
        //     icon: <svg className="icon" aria-hidden="true"><use xlinkHref={`${isItemInCart(data) ? "#cart" : "#cart"}`}></use></svg>,
        //     command: () => {
        //         if (isDelete) {
        //             toggleCartItem(data)

        //         } else {
        //             setSankbar(true);
        //             setSankbarText('You are not allowed!');
        //             setSankbarColor("warning");
        //         }
        //     },
        // },

        {
            label: "Remove To Cart",
            icon: <svg className="icon" aria-hidden="true"><use xlinkHref="#cart"></use></svg>,
            command: () => {
                // if (isDelete) {
                //     toggleCartItem(data)

                // } else {
                //     setSankbar(true);
                //     setSankbarText('You are not allowed!');
                //     setSankbarColor("warning");
                // }
            },
        }, {
            label: "Delete",
            icon: <svg className="icon" aria-hidden="true"><use xlinkHref="#red_delete"></use></svg>,
            command: () => {
                console.log(data);
                setLoader(true);
                favoriteDelete(data);
            },
        }
    ]);

    const buttonTemplate = (data) => (
        <SplitButton dropdownIcon={<svg className="icon" aria-hidden="true"><use xlinkHref="#table_dot"></use></svg>} className="p-button-sm" size="small" model={items(data)} />
    );

    const onGlobalFilterChange = (value) => {
        let _filters = { ...filtersData };
        _filters['global'].value = value;
        setFiltersData(_filters);
        setFilterValue(value);
    };

    const newstockHeader = () => {
        return (
            <div className='masterform'>
                <div className='input'>
                    <InputTypeText keyfilter='' type="search" icon={<FontAwesomeIcon icon={faMagnifyingGlass} />} name="Search" id="search" value={FilterValue} changeEvent={(value) => onGlobalFilterChange(value)} />
                </div>
                {/* <div className='input'>
                    <InputTypeText keyfilter='' type="search" icon={<svg width="19" height="19" viewBox="0 0 631 629" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" clipRule="evenodd" d="M469.64 512.837C476.899 506.849 483.871 500.515 490.532 493.858C500.573 483.76 509.902 472.978 518.451 461.59L620.057 563.323C633.864 577.146 633.864 599.558 620.057 613.382C606.251 627.205 583.867 627.205 570.061 613.382L469.64 512.837Z" fill="currentColor" />
                        <path opacity="0.32" fillRule="evenodd" clipRule="evenodd" d="M537.921 433.56C563.395 389.701 576.764 339.848 576.66 289.112C576.753 225.511 555.867 163.66 517.246 113.165C478.624 62.6697 424.429 26.3579 363.078 9.86978C301.728 -6.61837 236.656 -2.35999 177.971 21.9835C119.286 46.3269 70.2724 89.3928 38.5439 144.492C6.81537 199.59 -5.85213 263.638 2.50903 326.685C10.8702 389.733 39.792 448.251 84.782 493.15C129.772 538.05 188.312 566.817 251.308 574.984C314.305 583.15 378.231 570.259 433.159 538.312C441.159 533.751 448.901 528.749 456.349 523.332C469.146 514.093 481.185 503.845 492.35 492.685C503.455 481.518 513.688 469.514 522.959 456.779V456.639C528.349 449.216 533.343 441.512 537.921 433.56ZM334.189 520.465C379.997 511.349 422.077 488.837 455.11 455.774C477.247 433.921 494.825 407.879 506.823 379.163C518.821 350.447 525 319.629 525 288.5C525 257.371 518.821 226.553 506.823 197.837C494.825 169.121 477.247 143.079 455.11 121.226C422.077 88.1633 379.997 65.651 334.189 56.5355C288.382 47.42 240.904 52.1106 197.758 70.0143C154.612 87.918 117.736 118.231 91.7919 157.12C65.8475 196.01 52 241.73 52 288.5C52 335.27 65.8475 380.99 91.7919 419.88C117.736 458.769 154.612 489.082 197.758 506.986C240.904 524.889 288.382 529.58 334.189 520.465Z" fill="currentColor" />
                    </svg>
                    } value={FilterValue} changeEvent={(value) => onGlobalFilterChange(value)} name="Search" id="search" />
                </div> */}
                <div className='inputFilter'>
                    <div className='icons'>
                        <Tooltip target=".icon" position='top' />

                        <div className={!GridandListView ? 'icon active' : 'icon'} data-pr-tooltip="Grid view" data-pr-position="top" onClick={() => setGridandListView(false)}>
                            <svg aria-hidden="true"><use xlinkHref="#grid_view"></use></svg>
                        </div>

                        <div className={GridandListView ? 'icon active' : 'icon'} data-pr-tooltip="List view" data-pr-position="top" onClick={() => setGridandListView(true)}>
                            <svg aria-hidden="true"><use xlinkHref="#list_view"></use></svg>
                        </div>
                    </div>
                </div>
            </div>
        )
    };

    const imagesTag = (data) => {
        return (
            <div className='image'>
                {data.Imglink === '' ?
                    <div className='defultimage' style={{ WebkitMask: `url(${noimage})  center center / contain no-repeat` }}></div>
                    :
                    <Image  src={data.Imglink} zoomSrc={data.Imglink} id='Image_zoom' preview alt='image' />
                }
            </div>
        )
    };

    return (

        <div id='Favorite'>
            <div className='topview'>
                <div className='title'>
                    <h5>Favorite</h5>
                    <p>Dashboard<span></span>Favorite</p>
                </div>
            </div>

            {GridandListView ?
                <TableGrid
                    tableArray={favoriteArray}
                    columns={favoritecolumns}
                    actionButton={buttonTemplate}
                    header={newstockHeader}
                    imagesTag={imagesTag}
                    filters={filtersData}
                    filterDisplay="menu"
                    globalFilterFields={filtersKey}
                />
                :
                <div className='girdView'>
                    {newstockHeader()}

                    {favoriteArray.length !== 0 ?
                        <div className='maingrid'>
                            {favoriteArray.length > 0 ? favoriteArray.map((g, index) => (
                                <div className='boxGrid' key={index}>
                                    <div className='images'>
                                        <div className='icons'>
                                            <div className='icon'>
                                                {buttonTemplate(g)}
                                            </div>
                                        </div>

                                        <div className='image'>
                                            {g.Imglink === '' ?
                                                <React.Fragment>
                                                    <div className='defultimage' style={{ WebkitMask: `url(${noimage})  center center / contain no-repeat` }}></div>
                                                    <p>No image.jpg</p>
                                                </React.Fragment>
                                                :
                                                <Image src={g.Imglink} zoomSrc={g.Imglink} preview id='Image_zoom' alt='image' />}
                                        </div>
                                    </div>

                                    <div className='body'>
                                        <div className='title'>
                                            <h5>{Capitalize(g.PartyName)}</h5>
                                            <h6>{Capitalize(g.ItemName)}</h6>
                                        </div>
                                    </div>
                                </div>
                            )) : ""}
                        </div>
                        : <Nodataavailable />}
                </div>
            }

            {loader ? <Loader /> : ""}

            <SnackbarNotification open={sankbar} text={sankbarText} color={sankbarColor} sankbar={() => setSankbar()} />
        </div>
    );
}

export default Favorite;