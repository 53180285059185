import React, { useEffect, useRef } from 'react';
import './InputTypeNumber.scss';
import { InputNumber } from 'primereact/inputnumber';

function InputTypeNumber(props) {
    const valueData = props.value;
    const id = props.id;
    const name = props.name;
    const icon = props.icon;
    const min = props.min;
    const max = props.max;
    const maxLength = props.maxLength;
    const suffix = props.suffix;
    const prefix = props.prefix;
    const minFractionDigits = props.minFractionDigits;
    const read = props.read;
    const disabled = props.disabled;
    const className = props.className;
    const useGrouping = props.useGrouping;
    const tabIndex = props.tabIndex;

    const InputNumberRef = useRef(null);

    useEffect(() => {
        if (className) {
            InputNumberRef.current.focus()
        }
    }, [className]);

    const handleKeyDown = (e) => {
        if (e.target.value && e.target.value.length >= maxLength && e.key !== 'Backspace' && e.key !== 'Tab') {
            e.preventDefault();
        };
    };

    return (
        <div className={!icon ? "number" : "p-inputgroup"}>
            {!icon ? "" :
                <span className={className ? "p-inputgroup-addon p-invalid" : "p-inputgroup-addon"} >
                    {icon}
                </span>
            }
            <span className="p-float-label">
                <InputNumber
                    ref={InputNumberRef}
                    className={className ? "p-invalid" : ""}
                    id={id}
                    prefix={prefix}
                    onChange={(e) => {
                        if (typeof props.changeEvent === 'function') {
                            props.changeEvent(e.value, id);
                        }
                    }}
                    readOnly={read}
                    value={valueData ? valueData : null}
                    min={min}
                    max={max}
                    suffix={suffix}
                    minFractionDigits={minFractionDigits}
                    disabled={disabled}
                    useGrouping={useGrouping}
                    aria-valuenow={false}
                    tabIndex={tabIndex}
                    onKeyDown={(e) => handleKeyDown(e)}
                    autoComplete="off"
                />
                <label htmlFor={id} className={className ? "p-invalid" : ""}>{name}</label>
            </span>
        </div>
    );
}

export default InputTypeNumber;