import React, { useEffect, useRef, useState } from 'react';
import "./TableGrid.scss";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Capitalize } from '../GlobalFunction/globalFunction';
import nodata from "./../../assets/Img/nofound.svg"
import { Checkbox } from 'primereact/checkbox';
import { Toast } from 'primereact/toast';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAnglesLeft, faAnglesRight, faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';

function TableGrid(props) {
    const toast = useRef(null);

    let rows = props.tableArray;
    const columns = props.columns;
    const footer = props.footer;
    const selectedData = props.selectedData;
    const selectionMode = props.selectionMode;

    const nodatafoungd = () => {
        return (
            <React.Fragment>
                <div className='imagesnodata' style={{ background: `url(${nodata})  center center / contain no-repeat` }}></div>
                <p className="text-2xl text-center sm:text-4xl font-bold text-darkblue-500 mb-2">Ups!... no results found</p>
                <p className="text-xl text-gray-700">Please try another search</p>
            </React.Fragment>
        )
    };

    // Pagination
    const page = props.page;
    const size = props.size;
    const totalPage = props.totalPage;
    const pageRange = props.totalPageRange;
    const totalRow = props.totalRow;
    const pagination = props.pagination;

    const paginationDropdown = [
        { value: 25 },
        { value: 50 },
        { value: 100 },
    ];

    const [leftarrow, setLeftarrow] = useState(false);
    const [rightarrow, setRightarrow] = useState(false);
    const [leftarrowall, setLeftarrowall] = useState(false);
    const [rightarrowall, setRightarrowall] = useState(false);

    useEffect(() => {
        if (totalPage === 1 || totalPage === 0) {
            setRightarrow(true);
            setRightarrowall(true);
            setLeftarrowall(true);
            setLeftarrow(true);
        } else {
            setRightarrow(false);
            setRightarrowall(false);
            setLeftarrowall(false);
            setLeftarrow(false);
        };

        if (totalPage === page) {
            setRightarrow(true);
            setRightarrowall(true);
        } else if (page === 1) {
            setLeftarrowall(true);
            setLeftarrow(true);
        };
    }, [page, totalPage]);

    const AllArrow = (value) => {
        if (value) {
            props.setPage(totalPage);
            setRightarrow(true);
            setRightarrowall(true);
            setLeftarrowall(false);
            setLeftarrow(false);
        } else {
            props.setPage(1);
            setRightarrow(false);
            setRightarrowall(false);
            setLeftarrowall(true);
            setLeftarrow(true);
        };
    };

    const SingleArrow = (value) => {
        if (value) {
            console.log(totalPage, page, totalPage > page);
            if (totalPage > page) {
                props.setPage(page + 1);
                setRightarrow(false);
                setRightarrowall(false);
                setLeftarrowall(false);
                setLeftarrow(false);
            } else {
                props.setPage(totalPage);
                setRightarrow(true);
                setRightarrowall(true);
                setLeftarrowall(false);
                setLeftarrow(false);
            }
        } else {
            if (page > 1) {
                props.setPage(page - 1);
                setLeftarrow(false);
                setLeftarrowall(false);
                setRightarrow(false);
                setRightarrowall(false);
            } else {
                props.setPage(1);
                setLeftarrowall(true);
                setLeftarrow(true);
                setRightarrow(false);
                setRightarrowall(false);
            }
        }
    };

    return (
        <React.Fragment>
            <Toast ref={toast} />
            <DataTable
                scrollable
                showGridlines={false}
                value={rows}
                header={props.header ? props.header : ""}
                onRowDoubleClick={(event) => props.onRowDoubleClick ? props.onRowDoubleClick(event.data) : {}}
                emptyMessage={nodatafoungd}
                footer={footer ? props.footerTemplate ? props.footerTemplate() : false : false}
                selection={selectedData ? selectedData : false}
                selectionMode={selectionMode ? selectionMode : false}
            >
                {columns ? columns.length > 0 ? columns.map(({ field, header, hide, backcolor, columnstyle, forecolor }, index) => {
                    return (
                        <Column
                            key={index}

                            field={field}

                            header={Capitalize(header)}

                            filter={props.filter ? props.filter : false}

                            showFilterMenu={false}

                            sortable={props.sortable ? props.sortable : false}

                            style={{ color: forecolor, fontWeight: columnstyle }}

                            bodyStyle={{ background: backcolor, display: hide ? "none" : "table-cell" }}

                            headerStyle={{ background: backcolor + '50', fontWeight: columnstyle, color: forecolor, display: hide ? "none" : "table-cell" }}

                            headerClassName={field === "Fav" ? "favorite" : ""}

                            className={field === "action" ? props.actionButton ? "action" : "" : field === "Fav" ? "favorite" : ""}

                            frozen={field === 'action'}

                            alignFrozen={"right"}

                            body={
                                field === "action" ?
                                    props.actionButton ?
                                        props.actionButton : ""
                                    : field === "isActive" || field === 'IsActive' || field === 'ISLOCK' ?
                                        props.isActiveTag ?
                                            props.isActiveTag : ""
                                        : header === "Image" || header === "image" || header === "Photo" || header === "photo" ?
                                            props.imagesTag ?
                                                props.imagesTag : ""
                                            : field === "Fav" ?
                                                props.favorite ?
                                                    props.favorite : ""
                                                : field === "TRequired" || field === 'TReadOnly' || field === 'TShowbuttons' || field === 'TVisible' || field === 'TUsegrouping' || field === 'TRange' || field === 'TEnableDisable' || field === 'ISVIEW' || field === 'ISINS' || field === 'ISUPD' || field === 'ISDEL' || field === 'ISPASS' ?
                                                    props.isCheckbox ?
                                                        (rowData) => {
                                                            return (
                                                                <Checkbox
                                                                    checked={rowData[field]}
                                                                    value={rowData[field]}
                                                                    onChange={(e) => props.isCheckbox ? props.isCheckbox(e.checked, rowData, field) : {}} />
                                                            );
                                                        }
                                                        : ""
                                                    : field === "isDisplay" ?
                                                        props.PublisIcon ?
                                                            props.PublisIcon
                                                            : ""
                                                        : props.inputbox ?
                                                            props.inputbox
                                                            : ""
                            }

                            filterField={field}

                            filterElement={
                                field === "ISVIEW" || field === "ISINS" || field === "ISUPD" || field === "ISDEL" || field === "ISPASS" ?
                                    props.filterElement ?
                                        props.filterElement : ""
                                    : ""
                            }
                        />
                    );
                }) : "" : ""}
            </DataTable>

            {!pagination ?
                <div className='pagination'>
                    <div className='dropdown'>
                        <Dropdown
                            value={size}
                            onChange={(e) => { props.setSize(e.value); props.setPage(1); }}
                            options={paginationDropdown}
                            optionLabel="value"
                            panelClassName='dropdown_select'
                        />
                    </div>

                    <div className='arrow'>
                        <Button
                            icon={<FontAwesomeIcon className='font_icon' icon={faAnglesLeft} />}
                            onClick={() => AllArrow(false)} disabled={leftarrowall}
                        />
                    </div>

                    <div className='arrow'>
                        <Button
                            icon={<FontAwesomeIcon className='font_icon' icon={faChevronLeft} />}
                            onClick={() => SingleArrow(false)}
                            disabled={leftarrow}
                        />
                    </div>

                    <div className='pagerange'>
                        {`${pageRange} of ${totalRow}`}
                    </div>

                    <div className='arrow'>
                        <Button
                            icon={<FontAwesomeIcon className='font_icon' icon={faChevronRight} />}
                            onClick={() => SingleArrow(true)}
                            disabled={rightarrow}
                        />
                    </div>

                    <div className='arrow'>
                        <Button
                            icon={<FontAwesomeIcon className='font_icon' icon={faAnglesRight} />}
                            onClick={() => AllArrow(true)}
                            disabled={rightarrowall}
                        />
                    </div>
                </div> : ""}

        </React.Fragment>
    );
};

export default TableGrid;