import React, { useCallback, useEffect, useState } from 'react';
import './ImageUpload.scss';
import { useDropzone } from 'react-dropzone';
import SnackbarNotification from '../Snackbar/SnackbarNotification';
import { Checkbox } from "primereact/checkbox";
import noimage from './../../assets/Img/noimage.svg';

function ImageUpload(props) {

    const [selectedFile, setSelectedFile] = useState({});
    const [previewUrl, setPreviewUrl] = useState('');

    // Sank Bar
    const [sankbar, setSankbar] = useState(false);
    const [sankbarText, setSankbarText] = useState("");
    const [sankbarColor, setSankbarColor] = useState("");

    const [checked, setChecked] = useState(false);

    useEffect(() => {
        if (props.photo !== '') {
            setPreviewUrl(props.photo);
            setChecked(false);
        } else {
            setPreviewUrl(noimage);
            setChecked(true);
        }
    }, [props.photo]);

    const onDrop = useCallback((acceptedFiles) => {
        setChecked(false);
        acceptedFiles.forEach((file) => {
            if (file.size <= 3 * 1024 * 1024) {
                setSelectedFile(file);
                const reader = new FileReader();
                reader.addEventListener("load", () => {
                });
                props.setBineryFile(file);
                setSelectedFile(file);
                reader.onload = (event) => {
                    const base64String = event.target.result;
                    setPreviewUrl(base64String);
                    props.setPhotolink(base64String);
                };
                reader.readAsDataURL(acceptedFiles[0]);
            } else {
                setSankbar(true);
                setSankbarText("File size exceeds the 3 MB limit. Please choose a smaller file.");
                setSankbarColor("warning")
            }
        });
    }, []);

    const { getRootProps, getInputProps } = useDropzone({ onDrop });

    const removeImage = () => {
        setPreviewUrl("");
        props.setPhotolink('');
    }

    return (
        <div className='uploadImage'>
            {props.Default ?
                <div className='defultImage'>
                    <Checkbox onChange={e => {
                        setChecked(e.checked);
                        if (e.checked) {
                            setPreviewUrl(noimage);
                            props.setPhotolink('');
                        } else {
                            setPreviewUrl(props.photo);
                        }
                    }} checked={checked} inputId="ingredient1"></Checkbox>
                    <label htmlFor="ingredient1" className="ml-2">Default</label>
                </div>
                : ""}

            <label htmlFor='profilePhoto' {...getRootProps()} className="imagecontant">
                {previewUrl === '' ?
                    <div className='content'>
                        <svg className="icon" aria-hidden="true"><use xlinkHref="#upload"></use></svg>
                        <span className="title">
                            <h4>Drag and Drop Image Here</h4>
                            <span>Maximum 3MB Image Upload</span>
                        </span>
                    </div>
                    :
                    <div className='imageside'>
                        {checked ?
                            <div className='image'>
                                <div style={{ WebkitMask: `url(${previewUrl})  center center / contain no-repeat` }} className='defultimage'></div> </div> :
                            <div className='image'>
                                <img alt={previewUrl} className='image' role="presentation" src={previewUrl} />
                            </div>}
                        {!checked ? <svg className="icon" aria-hidden="true" onClick={() => removeImage()}><use xlinkHref="#red_delete"></use></svg> : ""}
                        <span>{checked ? "No image.jpg" : Object.keys(selectedFile).length > 0 ? selectedFile.name : previewUrl}</span>
                    </div>
                }
            </label>

            <div className='uploadInput'>
                <input {...getInputProps()} multiple={false} tabIndex={props.tabIndex} autoFocus />
            </div>

            <SnackbarNotification open={sankbar} text={sankbarText} color={sankbarColor} sankbar={(value) => setSankbar(value)} />
        </div>
    );
}

export default ImageUpload;
