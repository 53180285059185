import React from "react"
import PathConstants from "../Routes/pathConstants";
import MfgIssue from "../Manufacturing/ManufacturingIssue/ManufacturingIssue";
const Login = React.lazy(() => import("../Login/login"));
const Dashboard = React.lazy(() => import("../Dashboard/dashboard"));
const Master = React.lazy(() => import("../Master/Master/Master"));
const Party = React.lazy(() => import("../Master/Party/party"));
const Newstock = React.lazy(() => import("../Purchase/Newstock/Newstock"));
const Oldstock = React.lazy(() => import("../Purchase/Oldstock/Oldstock"));
const Rawstock = React.lazy(() => import("../Purchase/Rawstock/Rawstock"));
const Newuser = React.lazy(() => import("../User/Newuser/Newuser"));
const Userpermission = React.lazy(() => import("../User/Userpermission/Userpermission"));
const Settingpolicy = React.lazy(() => import("../SettingPolicy/SettingPolicy"));
const Sales_crm = React.lazy(() => import("../salesCRM/salesCRM"));
const Display = React.lazy(() => import("../salesCRM/Display/Display"));
const Order = React.lazy(() => import("../salesCRM/Order/Order"));
const Icon = React.lazy(() => import("../assets/Icons/GTIcon"));
const Favorite = React.lazy(() => import("../commanComponet/Favorite/Favorite"));
const Page500 = React.lazy(() => import("../commanComponet/500page/Page500"));
const Profile = React.lazy(() => import("../Profile/Profile"));
const Cart = React.lazy(() => import("../Cart/Cart/Cart"));
const Checkout = React.lazy(() => import("../Cart/Checkout/Checkout"));
const OrderSuccess = React.lazy(() => import("../Cart/OrderSuccess/OrderSuccess"));
const OrderCancel = React.lazy(() => import("../Cart/OrderCancel/OrderCancel"));
const ManufacturingIssue = React.lazy(() => import("../Manufacturing/ManufacturingIssue/ManufacturingIssueEntry/ManufacturingIssueEntry"));
const MfgRecive = React.lazy(() => import("../Manufacturing/MfgRecive/MfgRecive"));
const Accounting = React.lazy(() => import("../Accounting/Payment/Payment"));

const Demo = React.lazy(() => import("../demo/demo"));

const routes = [
    { path: PathConstants.Page500, element: <Page500 /> },
    { path: PathConstants.Login, element: <Login /> },
    { path: PathConstants.LoginPage, element: <Login /> },
    { path: `mdi/${PathConstants.Dashboard}`, element: <Dashboard /> },
    { path: `mdi/${PathConstants.Master}`, element: <Master /> },
    { path: `mdi/${PathConstants.Party}`, element: <Party /> },
    { path: `mdi/${PathConstants.Newstock}`, element: <Newstock /> },
    { path: `mdi/${PathConstants.Oldstock}`, element: <Oldstock /> },
    { path: `mdi/${PathConstants.Rawstock}`, element: <Rawstock /> },
    { path: `mdi/${PathConstants.Newuser}`, element: <Newuser /> },
    { path: `mdi/${PathConstants.Userpermission}`, element: <Userpermission /> },
    { path: `mdi/${PathConstants.Reportpermission}`, element: <Dashboard /> },
    { path: `mdi/${PathConstants.Usergroup}`, element: <Dashboard /> },
    { path: `mdi/${PathConstants.Settingpolicy}`, element: <Settingpolicy /> },
    { path: `mdi/${PathConstants.Sales_crm}`, element: <Sales_crm /> },
    { path: `mdi/${PathConstants.Icon}`, element: <Icon /> },
    { path: `mdi/${PathConstants.Favorite}`, element: <Favorite /> },
    { path: `mdi/${PathConstants.Profile}`, element: <Profile /> },
    { path: `mdi/${PathConstants.Cart}`, element: <Cart /> },
    { path: `mdi/${PathConstants.Checkout}`, element: <Checkout /> },
    { path: `mdi/${PathConstants.OrderSuccess}`, element: <OrderSuccess /> },
    { path: `mdi/${PathConstants.OrderCancel}`, element: <OrderCancel /> },
    { path: `mdi/${PathConstants.Display}`, element: <Display /> },
    { path: `mdi/${PathConstants.Order}`, element: <Order /> },
    { path: `mdi/${PathConstants.MfgIssue}`, element: <MfgIssue /> },
    { path: `mdi/${PathConstants.MfgRecive}`, element: <MfgRecive /> },
    { path: `mdi/${PathConstants.Demo}`, element: <Demo /> },
    { path: `mdi/${PathConstants.Accounting}`, element: <Accounting /> },
    { path: `mdi/${PathConstants.Demo}`, element: <Demo /> },
];

export default routes;