import React, { useEffect, useRef } from 'react';
import './ConfirmDialogModal.scss';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';

export default function ConfirmDialogModal(props) {
    const open = props.open;
    const massege = props.massege;
    const toast = useRef(null);

    useEffect(() => {
        if (open) {
            confirm();
        }
    }, [open]);

    const confirm = () => {
        confirmDialog({

            group: 'headless',
            message: massege,
            header: (
                <div className='con_pop'>
                    <div className="con_circle border-circle inline-flex justify-content-center align-items-center -mt-6">
                        <i className="pi pi-question text-5xl"></i>
                    </div>
                    <div className='mt-4 font-bold text-2xl block mb-2'>
                        Confirmation
                    </div>
                </div>
            ),
            // icon: 'pi pi-exclamation-triangle',
            acceptClassName: 'p-button-primary',
            rejectClassName: 'p-button-secondary',
            closable: false,
            accept: () => {
                if (props.onHide) {
                    props.onHide(false);
                }
                if (props.onclick) {
                    props.onclick(false);
                }
            },
            reject: () => {
                if (props.onHide) {
                    props.onHide(false);
                }
            },

        });
    };

    return (
        <React.Fragment>

            <Toast ref={toast} />
            <ConfirmDialog
                id='ConfirmDialog_pop'
            />
        </React.Fragment>
    );
}



