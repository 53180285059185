import React, { useEffect, useRef, useState, ChangeEventHandler, useContext, Profiler } from 'react';
import './header.scss';
import SearchIcon from '@mui/icons-material/Search';
import { Link } from 'react-router-dom';
import { useNavigate } from "react-router-dom";
import { Sidebar } from 'primereact/sidebar';
import { TabView, TabPanel } from 'primereact/tabview';
import { Badge } from 'primereact/badge';
import photo3 from "../assets/Img/Bhuman.jpg"
import { Button } from 'primereact/button';
import Favorite from '../commanComponet/Favorite/Favorite';
import { OverlayPanel } from 'primereact/overlaypanel';
import { Post } from '../commanAPI/commanAPI';
import SnackbarNotification from '../commanComponet/Snackbar/SnackbarNotification';
import { CartContext, PartypeCodecCntext } from '../App';
import Nodataavailable from '../commanComponet/Nodataavailable/Nodataavailable';
import { Dialog } from 'primereact/dialog';
import SelectDropdown from '../commanComponet/SelectDropdown/SelectDropdown';
import noimage from '../assets/Img/noimage.svg';
import InputTypeText from '../commanComponet/InputTypeText/InputTypeText';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';


function Header(props) {
    // const [visible, setVisible] = useState(false);
    const [favorite, setFavorite] = useState(false);
    const cartmodal = useRef(null);
    const navigate = useNavigate();

    const userData = JSON.parse(localStorage.getItem("userData"));

    const [partyModal, setPartyModal] = useState(false);
    // Loader
    const [loader, setLoader] = useState(false);
    // SankBar
    const [sankbar, setSankbar] = useState(false);
    const [sankbarText, setSankbarText] = useState("");
    const [sankbarColor, setSankbarColor] = useState("");

    const [fullaName, SetFullName] = useState();
    const [emailId, SetEmailId] = useState();
    const [shortName, SetShortName] = useState();
    // const [lightDark, setLightDark] = useState(false);

    // party name 
    const [getPartyData, setGetPartyData] = useState([])
    const [getPartyName, setPartyName] = useState(() => {
        const partyname = localStorage.getItem('partyname');
        return partyname ? JSON.parse(partyname) : null;
    });

    useEffect(() => {
        localStorage.setItem('partyname', JSON.stringify(getPartyName));
    }, [getPartyName]);


    const getparty = localStorage.getItem("partyname")

    // payment name 
    const [PaymentTypeOption, setPaymentTypeOption] = useState([]);
    const [PaymentType, setPaymentType] = useState(() => {
        const ptype = localStorage.getItem('paymentType');
        return ptype ? JSON.parse(ptype) : null;
    });


    useEffect(() => {
        localStorage.setItem('paymentType', JSON.stringify(PaymentType));
    }, [PaymentType]);

    const refreshlocal = () => {
        const partyname = JSON.parse(localStorage.getItem("partyname"));
        setPartyName(partyname)
        const getpaymentType = JSON.parse(localStorage.getItem("paymentType"));
        setPaymentType(getpaymentType)
    }

    useEffect(() => {
        const userData = JSON.parse(localStorage.getItem("userData"));
        if (userData !== null || undefined) {
            SetFullName(userData.Fullname);
            SetEmailId(userData.EmailId);
            SetShortName(userData.Fullname.substring(0, 1));
        }
    }, []);

    const Logout = () => {
        navigate("/login")
        sessionStorage.removeItem("header");
        sessionStorage.removeItem("FRM_Code");
        localStorage.removeItem('userData');
        localStorage.removeItem("login");
        localStorage.removeItem("ID");
        localStorage.removeItem("Error");
    }

    // dark mode
    const storedTheme = localStorage.getItem("theme");
    const prefersDark =
        window.matchMedia &&
        window.matchMedia("(prefers-color-scheme: dark)").matches;
    const defaultDark = storedTheme === "dark" || (storedTheme === null && prefersDark);

    // Initialize state based on stored theme
    const [lightDark, setLightDark] = useState(defaultDark);

    const [cartData, setCartData] = useState([]);

    const allId = cartData.map((item) => item.ReadyID)

    const [partypeCode, setPartypeCode] = useState()

    // const [cartToggle, setCartToggle] = useState(false);

    const [visibleBacklayer, setVisibleBacklayer] = useState(false);

    const cartper = userData?.Per.find((item) => item.F_NAME === "CRT")
    const favper = userData?.Per.find((item) => item.F_NAME === "FAV")
    const themper = userData?.Per.find((item) => item.F_NAME === "THM")
    const porfileper = userData?.Per.find((item) => item.F_NAME === "PFL")

    const [cartToggle, setCartToggle] = useState(false);
    const modalRef = useRef(null);

    useEffect(() => {
        const handleOutsideClick = (e) => {
            if (modalRef.current && !modalRef.current.contains(e.target)) {
                setCartToggle(false);
            }
        };

        const handleScroll = () => {
            // Close the modal when scrolling occurs
            setCartToggle(false);
        };

        if (cartToggle) {
            document.addEventListener('mousedown', handleOutsideClick);
            window.addEventListener('scroll', handleScroll);
        }

        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
            window.removeEventListener('scroll', handleScroll);
        };
    }, [cartToggle]);





    const openModal = () => {
        setCartToggle(true);
    };





    useEffect(() => {

        if (lightDark) {
            setDark();
        } else {
            setLight();
        }
    }, [lightDark,]);


    const handleBacklayer = () => {
        setCartToggle(false);
    };



    const setDark = () => {
        localStorage.setItem("theme", "dark");
        document.documentElement.setAttribute("data-theme", "dark");
    };

    const setLight = () => {
        localStorage.setItem("theme", "light");
        document.documentElement.setAttribute("data-theme", "light");
    };

    const toggleTheme = () => {
        setLightDark(prevMode => !prevMode);
    };

    const [liked, setLiked] = useState(false);

    const handleLikeClick = () => {
        setLiked(!liked);
    };

    const { cartItem, setCartItem } = useContext(CartContext);

    const DeleteProduct = (id) => {
        const IsDelete = cartItem.filter((item) => item.ReadyID !== id)
        setCartItem(IsDelete)
        setLoader(false);
        setSankbar(true);
        setSankbarText("Product Remove successfully");
        setSankbarColor("success");
    }

    const DeleteAllProduct = () => {
        localStorage.removeItem("cartItem")
        setCartItem([])

    }

    const onNewChange = (value, name) => {
        if (name === "paymenttype") {
            setPaymentType(value);
        }
        if (name === "partytype") {
            setPartyName(value);
        }
    };



    // useEffect(() => {
    //     headPermissiosn()
    // }, [])

    // const headPermissiosn = () => {
    //     Post("UserPer/GetFormPer", { USRGRP: userData?.UGROUP, F_NAME: sessionStorage.getItem('FRM_Code') }).then((res) => {
    //         if (res?.status === 1) {

    //         } else {
    //             setLoader(false);
    //             setSankbar(true);
    //             setSankbarText(res?.message);
    //             setSankbarColor("error");
    //         }
    //     });
    // }


    const getParCode = async () => {
        await Post("UserPer/GetFormPer", { USRGRP: userData?.UGROUP, F_NAME: 'CRT' }).then((res) => {
            if (res?.status === 1) {
                setPartypeCode(res?.UserData)
            } else {
                setLoader(false);
                setSankbar(true);
                setSankbarText(res?.message);
                setSankbarColor("error");
            }
        });
    };


    const RedirectCheckout = () => {
        if (getPartyName === null || PaymentType === null) {
            partyGetData()
            paymentType()
            setPartyModal(true)
        } else if (getPartyName === null) {
            // setPartyModal(true)
            refreshlocal()
        } else if (PaymentType === null) {
            // setPartyModal(true)
        } else {
            navigate('mdi/Cart/checkout')
            refreshlocal()
        }
    }

    const checkPartyName = () => {
        if (getPartyName == null || getPartyName == "") {
            setLoader(false);
            setSankbar(true);
            setSankbarText("please Select Party");
            setSankbarColor("error");
        } else if (PaymentType === null || PaymentType == "") {
            setSankbar(true);
            setSankbarText("please Select Payment type");
            setSankbarColor("error");
        } else {
            navigate('mdi/Cart/checkout')
            setPartyModal(false)
            refreshlocal()
        }
    }

    useEffect(() => {

    }, [partypeCode])


    const partyGetData = () => {
        Post('CmbFill', {
            Type: "PARMAST",
            tblType: partypeCode?.COL2,
            UniqueId: ""
        }).then((res) => {
            if (res) {
                if (res?.status === 1) {
                    setGetPartyData(res?.UserData)
                } else {
                    setLoader(false);
                    setSankbar(true);
                    setSankbarText(res?.message);
                    setSankbarColor("error");
                }
            }
        })
    };

    const paymentType = (value) => {
        Post('CmbFill', {
            Type: "OTHERMAST",
            tblType: partypeCode?.COL3,
            UniqueId: ""
        }).then((res) => {
            if (res) {
                if (res.status === 1) {
                    if (res.UserData.length > 0) {
                        setPaymentTypeOption(res.UserData);
                        if (value === undefined) {
                            setPaymentTypeOption(res.UserData);
                        } else {
                            setPaymentTypeOption(res.UserData);
                            for (let c = 0; c < res.UserData.length; c++) {
                                if (res.UserData[c].ParamValue_Id === Number(value)) {
                                    setPaymentType(res.UserData[c]);
                                }
                            }
                        }
                    } else {
                        setLoader(false);
                        setSankbar(true);
                        setSankbarText(res.message);
                        setSankbarColor("error");
                    }
                } else {
                    setLoader(false);
                    setSankbar(true);
                    setSankbarText(res.message);
                    setSankbarColor("error");
                }
            }
        })
    };



    // useEffect(() => {
    //     const body = document.querySelector("body");
    //     if (cartToggle) {
    //         body.classList.add("body_hidden");
    //     } else {
    //         body.classList.remove("body_hidden");
    //     }
    // }, [cartToggle]);


    return (
        <React.Fragment>
            <div id='Header' className={`${props.siderOpen ? "headerOpen" : "headerColse"}`}>
                <div className='user'>
                    <div className='siderbarIcon' onClick={() => props.setSidebarOpen(!props.siderOpen ? true : false)}>
                        <svg className="icon" aria-hidden="true"><use xlinkHref="#sidebar"></use></svg>
                    </div>
                    {/*                 
                <div className="like-button">
                    <div className="heart-bg">
                        <div className={`heart-icon ${liked ? 'liked' : ''}`} onClick={() => handleLikeClick()}></div>
                    </div>
                </div> */}
                    <div className='rightside'>
                        <FontAwesomeIcon icon={faMagnifyingGlass} />
                    </div>

                    <div className='leftside'>
                        {/* || refreshlocal() */}

                        <div className='cart_box'>

                            {
                                cartper?.ISVIEW == true && <div className='Aad_cart' onClick={(e) => openModal() || refreshlocal() || getParCode()}>
                                    <Badge className='cart_count' value={cartItem ? cartItem?.length : 0} severity="danger"></Badge>
                                    <svg className="icon" aria-hidden="true"><use xlinkHref="#cart"></use></svg>
                                </div>
                            }

                            <React.Fragment>
                                {cartToggle && (
                                    <div>
                                        <div id='cart_model' className='cart_model'>
                                            <div className="cart_pannel" ref={modalRef}>
                                                <h5 className="top_head">Cart Item ({cartItem?.length})</h5>
                                                <hr />
                                                <div className='cart_height'>
                                                    {cartItem.length !== 0 ? (
                                                        cartItem.map((Item, index) => (
                                                            <div className="cart_row" key={index}>
                                                                <div className="cart_img">
                                                                    {Item?.Imglink === "" ? (
                                                                        <div className='defultimage' style={{ WebkitMask: `url(${noimage})  center center / contain no-repeat` }}></div>
                                                                    ) : (
                                                                        <img src={Item?.Imglink} alt="" />
                                                                    )}
                                                                </div>
                                                                <div className="cart_title">
                                                                    <h5>{Item?.ItemName}</h5>
                                                                    <p>{Item?.PartyName}</p>
                                                                </div>
                                                                <div className="cart_delete_btn" onClick={() => DeleteProduct(Item.ReadyID)}>
                                                                    <svg aria-hidden="true"><use xlinkHref="#delete"></use></svg>
                                                                </div>
                                                            </div>
                                                        ))
                                                    ) : (
                                                        <Nodataavailable />
                                                    )}
                                                </div>

                                                {cartItem.length > 0 && (
                                                    <div className="bottom_btn">
                                                        <Link to="mdi/Cart/Cart"><Button>View Cart</Button></Link>
                                                        <Button onClick={RedirectCheckout}>Check Out</Button>
                                                        <Button onClick={DeleteAllProduct}>Delete All</Button>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        <div className='Backlayer' onClick={() => setCartToggle(false)}></div>
                                    </div>
                                )}


                            </React.Fragment>

                        </div>




                        {/* {
                        cartper?.ISVIEW == true && <div className='Aad_cart' onClick={(e) => cartmodal.current.toggle(e) || refreshlocal() || getParCode()} >
                            <Badge className='cart_count' value={cartItem ? cartItem?.length : 0} severity="danger"></Badge>
                            <svg className="icon" aria-hidden="true"><use xlinkHref="#cart"></use></svg>
                        </div>
                    } */}



                        <Dialog header="Check Out" visible={partyModal} onHide={() => setPartyModal(false)} id="cartcheck_out">
                            <div className='input'>
                                <SelectDropdown
                                    changeEvent={(value, name) => onNewChange(value, name)}
                                    value={getPartyName}
                                    // onFocus={() => partyGetData()}
                                    name={"Select Party*"}
                                    id={"partytype"}
                                    options={getPartyData}
                                    optionsName={'FullName'}
                                    icon={<svg className="icon" aria-hidden="true"><use xlinkHref="#party_name"></use></svg>}
                                />
                            </div>
                            <div className='input mt-3'>
                                <SelectDropdown
                                    // onFocus={() => paymentType()}
                                    changeEvent={(value, name) => onNewChange(value, name)}
                                    value={PaymentType}
                                    name={"Payment type*"}
                                    id={"paymenttype"}
                                    options={PaymentTypeOption}
                                    optionsName={'ParamValue'}
                                    icon={<svg className="icon" aria-hidden="true"><use xlinkHref="#payment_type"></use></svg>}
                                />
                            </div>
                            <div className='checkout'>
                                <Button className='check_out' onClick={checkPartyName || refreshlocal()}>  check Out </Button>
                            </div>
                        </Dialog>


                        {
                            favper?.ISVIEW == true && <div className='favorite'>
                                {!favorite ?
                                    <svg className="icon" onClick={() => navigate("/mdi/favorite")} aria-hidden="true"><use xlinkHref="#red_favfiill"></use></svg>
                                    :
                                    <Favorite onClick={() => setFavorite(false)} onHide={() => setFavorite(false)} />
                                }
                            </div>
                        }

                        {/* <div>
                        <svg className="icon" aria-hidden="true" onClick={() => setVisible(true)}><use xlinkHref="#notification"></use></svg>

                        <Sidebar className="w-full sm:w-25rem" showCloseIcon={false} position="right" icons={<div className='notifications'><p className='Notificationstext'>Notifications</p><svg className="icon" aria-hidden="true" onClick={() => setVisible(false)}><use xlinkHref="#close"></use></svg></div>} visible={visible} onHide={() => setVisible(false)} >

                            <TabView>
                                <TabPanel header="All" rightIcon="tagCount" >
                                    <div className='messagebox'>
                                        <div className='message'>
                                            <div className='image'>
                                                <img src={photo3} alt='images' className='photo3'></img>
                                            </div>
                                            <div className='mailbox'>
                                                <p className='mail'><strong>Bhuman Goyani </strong> sent you a friend request</p>
                                                <p> 16 minutes ago .Communication</p>
                                                <div className='acceptbtn'>
                                                    <button className='accet' type='button'>Accept</button>
                                                    <button className='decline' type='button'>Decline</button>
                                                </div>
                                            </div>
                                        </div>
                                        
                                    </div>
                                </TabPanel>
                                <TabPanel header="Unread" rightIcon="tagCount1">
                                    <div className='messagebox'>

                                        <div className='message'>
                                            <div className='image'>
                                                <img src={photo3} alt='images' className='photo3'></img>
                                            </div>
                                            <div className='mailbox'>
                                                <p className='mail'><strong>Bhuman Goyani </strong> sent you a friend request</p>
                                                <p> 16 minutes ago .Communication</p>
                                                <div className='acceptbtn'>
                                                    <button className='accet' type='button'>Accept</button>
                                                    <button className='decline' type='button'>Decline</button>
                                                </div>
                                            </div>

                                        </div>
                                         
                                    </div>
                                </TabPanel>
                                <TabPanel header="Archived" rightIcon="tagCount2" >
                                    <div className='messagebox'>
                                        <div className='message'>
                                            <div className='image'>
                                                <img src={photo3} alt='images' className='photo3'></img>
                                            </div>
                                            <div className='mailbox'>
                                                <p className='mail'><strong>Bhuman Goyani </strong> sent you a friend request</p>
                                                <p> 16 minutes ago .Communication</p>
                                                <div className='acceptbtn'>
                                                    <button className='accet' type='button'>Accept</button>
                                                    <button className='decline' type='button'>Decline</button>
                                                </div>
                                            </div>

                                        </div>
                                         
                                    </div>
                                </TabPanel>
                            </TabView>
                        </Sidebar>
                    </div> */}

                        {
                            themper?.ISVIEW == true && <div>
                                {!lightDark ? <svg className="icon" aria-hidden="true" onChange={() => toggleTheme()} onClick={() => { setLightDark(true); setDark(); }}><use xlinkHref="#sun_light"></use></svg> :
                                    <svg className="icon" aria-hidden="true" onChange={() => toggleTheme()} onClick={() => { setLightDark(false); setLight(); }}><use xlinkHref="#moon_light"></use></svg>}
                            </div>

                        }


                        {
                            porfileper?.ISVIEW == true && <div className='letter_word'>
                                <h2>{shortName}</h2>
                                <div className='toggleBar'>
                                    <div className='nameBar'>
                                        <div className='letter_word2'>
                                            <h2>{shortName}</h2>
                                        </div>
                                        <div className="username">
                                            <h6>{fullaName}</h6>
                                            <p>{emailId}</p>
                                        </div>
                                    </div>
                                    <nav className='navigation'>
                                        <ul>
                                            <li onClick={() => navigate("/mdi/profile")}>Profile</li>
                                            <li onClick={() => Logout()}> Log Out </li>
                                        </ul>
                                    </nav>
                                </div>
                            </div>
                        }

                    </div>
                </div>


                <SnackbarNotification open={sankbar} text={sankbarText} color={sankbarColor} sankbar={() => setSankbar()} />
            </div>

        </React.Fragment>
    );
}

export default Header;
