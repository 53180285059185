import React, { useEffect, useRef } from 'react';
import './InputTypeText.scss';
import { InputText } from "primereact/inputtext";
import { InvalidDropDown, InvalidEmail, InvalidPanCardNo } from '../GlobalFunction/globalFunction';

function InputTypeText(props) {
    const value = props.value;
    const id = props.id;
    const name = props.name;
    const icon = props.icon;
    const keyfilter = props.keyfilter;
    const disabled = props.disabled;
    const className = props.className !== undefined && props.className !== '' ? props.className : false;
    const maxLength = props.maxLength;
    const type = props.type;
    const tabIndex = props.tabIndex;
    const autoFocus = props.autoFocus;
    const readOnly = props.readOnly;
    const placeholder = props.placeholder;
    const background = props.background;
    const defaultValue = props.defaultValue;
    const color = props.color;
    const fontSize = props.fontSize;
    const fontweight = props.fontweight;
    const required = props.required;
    const visible = props.visible;

    const InputTextRef = useRef(null);

    useEffect(() => {
        if (className) {
            InputTextRef.current.focus();
        }
    }, [className]);

    return (
        <div className={!icon ? "number" : "p-inputgroup"} >
            {!icon ? "" :
                <span className={
                    id === "pancard" ?
                        className ?
                            !InvalidPanCardNo(value) ?
                                `p-inputgroup-addon p-invalid ${disabled ? "disabled" : ""}`
                                : `p-inputgroup-addon ${disabled ? "disabled" : ""}`
                            : `p-inputgroup-addon ${disabled ? "disabled" : ""}`
                        : keyfilter === "email" ?
                            className ?
                                !InvalidEmail(value) ?
                                    `p-inputgroup-addon p-invalid ${disabled ? "disabled" : ""}`
                                    : `p-inputgroup-addon ${disabled ? "disabled" : ""}`
                                : `p-inputgroup-addon ${disabled ? "disabled" : ""}`
                            : className ?
                                `p-inputgroup-addon p-invalid ${disabled ? "disabled" : ""}`
                                : `p-inputgroup-addon ${disabled ? "disabled" : ""}`
                }>
                    {icon}
                </span>
            }
            <span className="p-float-label">
                <InputText
                    ref={InputTextRef}
                    style={{ display: visible ? 'none' : 'block', background: background, color: color, fontSize: fontSize === 0 ? '' : fontSize, fontweight: fontweight === 0 ? '' : fontweight }}
                    type={type !== undefined ? type : ""}
                    maxLength={maxLength}
                    className={
                        id === "pancard" ?
                            className ?
                                !InvalidPanCardNo(value) ?
                                    "p-invalid"
                                    : ""
                                : ""
                            : keyfilter === "email" ?
                                className ?
                                    !InvalidEmail(value) ?
                                        "p-invalid"
                                        : ""
                                    : ""
                                : className ?
                                    "p-invalid"
                                    : ""
                    }
                    keyfilter={keyfilter}
                    placeholder={placeholder}
                    id={id}
                    value={value}
                    onChange={(e) => {
                        if (typeof props.changeEvent === 'function') {
                            props.changeEvent(e.target.value, id);
                        }
                    }}
                    disabled={disabled}
                    aria-valuenow={false}
                    onKeyDown={(e) => props.keyDown !== undefined ? props.keyDown(e) : ''}
                    tabIndex={tabIndex}
                    autoFocus={autoFocus}
                    readOnly={readOnly}
                    defaultValue={defaultValue}
                    required={required}
                    autoComplete="off"
                />
                <label htmlFor={id} className={id === "pancard" ? className ? !InvalidPanCardNo(value) ? "p-invalid" : "" : "" : keyfilter === "email" ? className ? !InvalidEmail(value) ? "p-invalid" : "" : "" : className ? !InvalidDropDown(value) ? "p-invalid" : "" : ""}>{name}</label>
            </span>
        </div>
    );
}
export default InputTypeText;