import React, { useState } from 'react'

import MfgTrnIssueView from './Manufacturingtransaction/Manufacturingtransaction';
import { TabPanel, TabView } from 'primereact/tabview';
import ManufacturingIssueEntry from './ManufacturingIssueEntry/ManufacturingIssueEntry';
import './ManufacturingIssue.scss'

const ManufacturingIssue = () => {


    const [activeTab, setActiveTab] = useState(0);

    const [SingleData, setSingleData] = useState([]);

    return (
        <div id='mfgissues' >
            <div className='topview'>
                <div className='title'>
                    <h5>Manufacturing</h5>
                </div>
            </div>

            <TabView activeIndex={activeTab} onTabChange={(e) => { setActiveTab(e.index); setSingleData([]) }} id="mfgissuetabview">
                <TabPanel header="Manufacturing entry">
                    <ManufacturingIssueEntry
                        SingleData={SingleData}
                        setSingleData={setSingleData}
                    />
                </TabPanel>

                <TabPanel header="Manufacturing view">
                    <MfgTrnIssueView
                        changeTab={setActiveTab}
                        setSingleData={setSingleData}
                    />
                </TabPanel>
            </TabView>
        </div>
    )
}

export default ManufacturingIssue;