import React, { useEffect, useState } from 'react';
import './sidebar.scss';
import logo from "./../assets/Logo/logo 25.png";
import { useNavigate } from 'react-router-dom';
import { Accordion, AccordionTab } from 'primereact/accordion';
import master from "./../assets/Icons/svgs/master.svg";
import dashboard from "./../assets/Icons/svgs/dashboard.svg";
import purchase from "./../assets/Icons/svgs/purchase.svg";
import salesCrm from "./../assets/Icons/svgs/sales_crm.svg";
import setting from "./../assets/Icons/svgs/setting.svg";
import userGroup from "./../assets/Icons/svgs/user_group.svg";
import report from "./../assets/Icons/svgs/report.svg";
import jwtDecode from 'jwt-decode';
import { Capitalize } from '../commanComponet/GlobalFunction/globalFunction';

function Sidebar(props) {
    const navigate = useNavigate();

    const [sidebarData, setSidebardata] = useState([]);

    const userData = JSON.parse(localStorage.getItem("userData"));

    var call = true;

    const serverData = userData !== null ? userData.Per : null;


    // const waveBtn = () => {
    //         const btns = document.querySelectorAll('.p-accordion-header-link');

    //         btns.forEach(btn => {
    //             btn.addEventListener('mousedown', function (e) {
    //                 const newRound = document.createElement('div');
    //                 newRound.className = 'cercle';
    //                 this.appendChild(newRound);

    //                 const rect = this.getBoundingClientRect();
    //                 const x = e.clientX - rect.left;
    //                 const y = e.clientY - rect.top;

    //                 newRound.style.left = `${x}px`;
    //                 newRound.style.top = `${y}px`;
    //                 newRound.classList.add('anim');

    //                 setTimeout(() => {
    //                     newRound.remove();
    //                 }, 1000);
    //             });
    //         });
    //     };
    // useEffect(() => {

    //     waveBtn();


    //     // Cleanup function
    //     return () => {
    //         const btns = document.querySelectorAll('.p-accordion-header-link');
    //         btns.forEach(btn => {
    //             btn.removeEventListener('mousedown', waveBtn);
    //         });
    //     };
    // }, []);


    useEffect(() => {
        if (call) {
            initializeSidebarData();
            call = false;
        }
    }, []);

    const initializeSidebarData = () => {
        var url = window.location.pathname.split("/");
        if (serverData !== null) {
            const result = [];
            const groupedData = serverData.reduce((acc, item) => {
                const tab = item.TAB;
                if (!acc[tab]) {
                    acc[tab] = [];
                }
                acc[tab].push(item);
                return acc;
            }, {});

            for (const tab in groupedData) {
                const tabData = groupedData[tab];

                const tabResult = [
                    tabData[0].TAB_DESC,
                    tabData[0].TAB === "DSBD" ? dashboard : tabData[0].TAB === "MSTR" ? master : tabData[0].TAB === "PRCS" ? purchase : tabData[0].TAB === "SCRM" ? salesCrm : tabData[0].TAB === "STAP" ? setting : tabData[0].TAB === "USER" ? userGroup : tabData[0].TAB === "MNFG" ? purchase : tabData[0].TAB === "ACCN" ? report : "",
                    tabData[0].TAB_DESC.toLowerCase().replace(/ /g, ''),
                    [],
                    tabData[0].TAB,
                    tabData[0].TAB,
                    tabData[0].ISVIEW
                ];

                if (tabData.length > 1) {
                    const subItems = tabData.slice(0).map(item => (item.ISVIEW ? [
                        item.F_DESC,
                        item.F_DESC,
                        tabData[0].TAB_DESC.toLowerCase().replace(/ /g, '') + "/" + item.F_DESC.toLowerCase().replace(/ /g, ''),
                        item.F_NAME,
                        item.F_NAME,
                    ] : []));

                    tabResult[3] = subItems;
                }

                result.push(tabResult);
            }


            const newData = result?.map((item) => {
                if (item[2] === url[2]) {
                    const subNewData = item[3].map((subItem) => {
                        if (subItem) {
                            if (subItem[2]?.split("/")[1] === url[3]) {
                                return [subItem[0], subItem[1], subItem[2], subItem[3], true];
                            } else {
                                return [subItem[0], subItem[1], subItem[2], subItem[3], false];
                            }
                        }
                    });
                    return [item[0], item[1], item[2], subNewData, item[4], true, item[6]];
                } else {
                    return [item[0], item[1], item[2], item[3], item[4], false, item[6]];
                }
            });

            setSidebardata(newData);
        }

    };

    const handleSubItemClick = (subItem, code) => {
        const token = localStorage.getItem("ID");
        if (token) {
            const decodedToken = jwtDecode(token);
            const currentTime = Date.now() / 1000;
            if (decodedToken.exp < currentTime) {
                navigate("/login");
            } else {
                navigate("/mdi/" + subItem);
                sessionStorage.setItem("FRM_Code", code);
                initializeSidebarData();
            };
        } else {
            navigate("/login");
        };
    };


    return (
        <div id='Sidebar' className={`${props.siderOpen ? "sideBarOpen" : "sideBarClose"}`}>

            <div className='Blacklayout' onClick={() => props.setSidebarOpen(false)}></div>

            <div className={`Sidebar ${props.siderOpen ? "open" : "close"}`}>

                <div className='logo'>
                    <div className='short_logo'>
                        <img src={logo} />
                    </div>
                    <div className='arrow' onClick={() => { !props.siderOpen ? props.setSidebarOpen(true) : props.setSidebarOpen(false) }}>
                        {props.siderOpen ?
                            <svg className="icon" aria-hidden="true"><use xlinkHref="#left"></use></svg>
                            :
                            <svg className="icon" aria-hidden="true"><use xlinkHref="#right"></use></svg>
                        }
                    </div>
                </div>

                <div className='navbar'>
                    {sidebarData.length > 0 ? sidebarData.map((item, index) => {
                        if (item[0] === "") {
                            return null
                        }

                        if (item[6]) {
                            return (
                                <Accordion key={index} expandIcon={item[3].length > 0 ? false : true} onClick={() => !props.siderOpen ? props.setSidebarOpen(true) : ''} collapseIcon={item[3].length > 0 ? false : true} className={`${item[5] !== undefined ? item[5] ? "show" : "" : ""}`}>
                                    <AccordionTab className={`${props.siderOpen ? "active" : ""}`} header={<div className={`xyz flex align-items-center ${props.siderOpen ? "open" : ""}`}><span className='icon' style={{ WebkitMask: `url(${item[1]})  center center / contain no-repeat` }}></span><span className="vertical-align-middle">{Capitalize(item[0])}</span></div>} onClick={() => {
                                        if (item[3].length === 0) {
                                            handleSubItemClick(item[2], item[4]);
                                            props.setSidebarOpen(false);
                                        }
                                    }}>
                                        {item[3].length > 0 ? item[3].map((subItem, subIndex) => {
                                            if (subItem.length > 0) {
                                                return (
                                                    <div className={`subMenu ${props.siderOpen ? "active" : ""} ${subItem[4] !== undefined ? subItem[4] ? "show" : "" : ""}`} onClick={() => { handleSubItemClick(subItem[2], subItem[3]); props.setSidebarOpen(false); }} key={subIndex}>
                                                        <div className={`flex align-items-center ${props.siderOpen ? "open" : ""}`}>
                                                            <span className='icon'></span>
                                                            <span className="vertical-align-middle">
                                                                {Capitalize(subItem[0])}
                                                            </span>
                                                        </div>
                                                    </div>
                                                )
                                            }
                                        }) : ""}
                                    </AccordionTab>
                                </Accordion>
                            );
                        };
                    }) : ""}
                </div>
            </div>

        </div>
    );
}

export default Sidebar;