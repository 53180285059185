import React from 'react';
import './loader.scss';
function Loader() {
    return (
        <div className='loader'>
            <dotlottie-player className="lottie" src="https://lottie.host/05b4bae9-c6a3-4233-a7bd-d956144bd9cd/qZgABC8wV7.json" background="transparent" speed="1" loop autoplay></dotlottie-player>
        </div>
    );
}

export default Loader;
