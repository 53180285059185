import React, { createContext, useEffect, useRef, useState } from 'react';
import './App.scss';
import './assets/css/country.css';
import './assets/Icons/IconFont.css';
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Login from "./Login/login";
import jwtDecode from 'jwt-decode';
import routes from "./Routes";
import Layout from "./Routes/Layout";
import Page404 from './commanComponet/404page/Page404';
import Page500 from './commanComponet/500page/Page500';


export const CartContext = createContext();
export const CheckoutContext = createContext();
// export const PartypeCodecCntext = createContext()


function App() {

  const storedTheme = localStorage.getItem("theme");
  const prefersDark = window.matchMedia && window.matchMedia("(prefers-color-scheme: dark)").matches;
  const defaultDark = storedTheme === "dark" || (storedTheme === null && prefersDark);

  const [isLoggedIn, setIsLoggedIn] = useState(localStorage.getItem('ID') ? true : false);
  const [lightDark] = useState(defaultDark);

  const inactivityTimeout = useRef(null);

  setInterval(() => {
    if (localStorage.getItem('ID')) {
      setIsLoggedIn(true);
    } else {
      setIsLoggedIn(false);
    }
  }, 100);



  useEffect(() => {
    if (window.location.pathname === "/" || window.location.pathname === "/login") {
      sessionStorage.removeItem("header");
      sessionStorage.removeItem("FRM_Code");
      localStorage.removeItem('userData');
      localStorage.removeItem("login");
      localStorage.removeItem("ID");
      localStorage.removeItem("Error");

      localStorage.removeItem("cartItem");
      localStorage.removeItem("checkoutReadyId");
      sessionStorage.removeItem("country")

    }

    const handleActivity = () => {
      clearTimeout(inactivityTimeout.current);
      inactivityTimeout.current = setTimeout(() => {
        sessionStorage.removeItem("header");
        sessionStorage.removeItem("FRM_Code");
        localStorage.removeItem('userData');
        localStorage.removeItem("login");
        localStorage.removeItem("ID");
        localStorage.removeItem("Error");

        localStorage.removeItem("cartItem");
        localStorage.removeItem("checkoutReadyId")
        sessionStorage.removeItem("country")

        setIsLoggedIn(true);
        checkTokan();
      }, 60 * 60 * 1000);
    };

    window.addEventListener('mousemove', handleActivity);
    window.addEventListener('keydown', handleActivity);

    if (lightDark) {
      setDark();
    } else {
      setLight();
    };

    return () => {
      clearTimeout(inactivityTimeout.current);
      window.removeEventListener('mousemove', handleActivity);
      window.removeEventListener('keydown', handleActivity);
    };

  }, []);

  const checkTokan = () => {
    const token = localStorage.getItem('ID');
    if (token) {
      const decodedToken = jwtDecode(token, "1234567890123456789");
      const currentTime = Date.now() / 1000;
      console.log(decodedToken.exp < currentTime, decodedToken.exp < currentTime);
      if (decodedToken.exp < currentTime) {
        setIsLoggedIn(false);
      } else {
        setIsLoggedIn(true);
      }
    } else {
      setIsLoggedIn(false);
    }
  }

  const setDark = () => {
    localStorage.setItem("theme", "dark");
    document.documentElement.setAttribute("data-theme", "dark");
  };

  const setLight = () => {
    localStorage.setItem("theme", "light");
    document.documentElement.setAttribute("data-theme", "light");
  };

  const [cartItem, setCartItem] = useState(() => {
    const AddToCart = isLoggedIn && localStorage.getItem('cartItem');
    return AddToCart ? JSON.parse(AddToCart) : [];
  });



  if (cartItem && cartItem.length === 0) {
    localStorage.removeItem("partyname")
    localStorage.removeItem("paymentType")
  }

  useEffect(() => {
    localStorage.setItem("cartItem", JSON.stringify(cartItem))
  }, [cartItem])

  // const [getReadyId, setGetReadyId] = useState(cartItem.map(item => item.ReadyID))

  const [getReadyId, setGetReadyId] = useState(() => {
    const getReadyId = isLoggedIn && localStorage.getItem('checkoutReadyId');
    return getReadyId ? JSON.parse(getReadyId) : true;
  });



  const ReadyId = cartItem.map(item => item.ReadyID)

  useEffect(() => {
    localStorage.setItem('checkoutReadyId', JSON.stringify(ReadyId.join(",")));
  }, [ReadyId]);


  if (!isLoggedIn) {
    localStorage.removeItem("cartItem");
    localStorage.removeItem("checkoutReadyId")
  }





  const router = createBrowserRouter([
    {
      element: isLoggedIn ? <CartContext.Provider value={{ cartItem, setCartItem }}>  <Layout />      </CartContext.Provider> : <Login />,
      errorElement: window.location.pathname === '500' ? <Page500 /> : <Page404 />,
      children: routes
    }
  ]);



  return <div className='app'><RouterProvider router={router} />  </div>;
}

export default App;
