import React, { useEffect, useState } from 'react'
import "./Manufacturingtransaction.scss"
import { Capitalize, decryptValue } from '../../../commanComponet/GlobalFunction/globalFunction';
import { Post } from '../../../commanAPI/commanAPI';
import PasswordBox from '../../../commanComponet/PasswordBox/PasswordBox';
import SnackbarNotification from '../../../commanComponet/Snackbar/SnackbarNotification';
import ConfirmDialogModal from '../../../commanComponet/ConfirmDialogModal/ConfirmDialogModal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import InputTypeText from '../../../commanComponet/InputTypeText/InputTypeText';
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
import { Tooltip } from 'primereact/tooltip';
import TableGrid from '../../../commanComponet/TableGrid/TableGrid';
import { SplitButton } from 'primereact/splitbutton';
import noimage from '../../../assets/Img/noimage.svg';
import Loader from '../../../commanComponet/Loader/loader';
import { Image } from 'primereact/image';

function Manufacturingtransaction({ changeTab, setSingleData }) {
    const userData = JSON.parse(localStorage.getItem("userData"));

    // Loader
    const [loader, setLoader] = useState(false);

    // SankBar
    const [sankbar, setSankbar] = useState(false);
    const [sankbarText, setSankbarText] = useState("");
    const [sankbarColor, setSankbarColor] = useState("");

    // Password check
    const [verifyPassword, setVerifyPassword] = useState(false);
    const [getPassword, setGetPassword] = useState('');
    const [isUpdate, setIsUpdate] = useState(false);
    const [isDelete, setIsDelete] = useState(false);

    // Filter
    const [FilterValue, setFilterValue] = useState('');
    const [filtersKey] = useState([]);

    // Confirmation
    const [confirmModel, setConfirmModel] = useState(false);
    const [confirmText, setConfirmText] = useState('');

    // Grid and List view
    const [GridandListView, setGridandListView] = useState(false);

    // Table columns
    const [ManufacturingColumns, setManufacturingColumns] = useState([]);
    const [manufacturingArray, setManufacturingArray] = useState([]);
    const [manufacturingId, setManufacturingId] = useState(0);

    // pagination 
    const [page, setPage] = useState(1);
    const [size, setSize] = useState(25);
    const [totalRow, setTotalRow] = useState(0);
    const [totalPage, setTotalPage] = useState(0);
    const [totalPageRange, setTotalPageRange] = useState(0);

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, [manufacturingArray]);

    const handleScroll = () => {
        if (window.innerHeight + document.documentElement.scrollTop === document.documentElement.offsetHeight) {
            if (totalPage >= Number(page + 1)) {
                if (!GridandListView) {
                    setPage(prevPage => prevPage + 1);
                }
            };
        };
    };

    useEffect(() => {
        if (totalPage !== 0) {
            if (totalRow > size) {
                getManufacturing(null, page);
            } else if (totalRow < size) {
                getManufacturing(null, 1);
            } else {
                getManufacturing(null, 1);
            };
            if (GridandListView) {
                setManufacturingArray([]);
            }
        };
    }, [page, size]);

    let manufacturingView = false;
    useEffect(() => {
        if (!manufacturingView) {
            setLoader(true);
            checkpassword(false);
            manufacturingView = true;
        }
    }, []);

    const checkpassword = (value) => {
        Post("UserPer/GetFormPer", { USRGRP: userData?.UGROUP, F_NAME: sessionStorage.getItem('FRM_Code') }).then((res) => {
            if (res) {
                if (res?.status === 1) {
                    setIsUpdate(res?.UserData.ISUPD);
                    setIsDelete(res?.UserData.ISDEL);
                    if (res?.UserData.ISPASS) {
                        setVerifyPassword(value);
                        const password = decryptValue(res?.UserData.PASS);
                        setGetPassword(password);
                    } else {
                        if (value) {
                            deleteMFG()
                        };
                    }
                    if (!value) {
                        tableDataCode("manufacturingView");
                    }
                } else {
                    setLoader(false);
                    setSankbar(true);
                    setSankbarText(res?.message);
                    setSankbarColor("error");
                }
            }
        });
    };

    const tableDataCode = (code) => {
        Post("ViewPara/GetFormWise", { VIEWNAME: code }).then((res) => {
            if (res) {
                if (res?.status === 1) {
                    if (res?.UserData.length > 0) {
                        setManufacturingColumns(res?.UserData);
                        res?.UserData.forEach((item) => {
                            const fieldName = item.field;
                            if (!item.hide) {
                                filtersKey.push(fieldName);
                            }
                        });
                    } else {
                        setLoader(false);
                        setSankbar(true);
                        setSankbarText(res?.message);
                        setSankbarColor("warning");
                    }
                    getManufacturing();
                } else {
                    setLoader(false);
                    setSankbar(true);
                    setSankbarText(res?.message);
                    setSankbarColor("error");
                };
            };
        });
    };

    const getManufacturing = (MfgID, pageCount, serach) => {
        Post('MfgIss/MfgTrnView', {
            "MfgID": MfgID ? MfgID : 0,
            "page": pageCount ? pageCount : page,
            "size": size,
            "search": serach ? serach : ""
        }).then((res) => {
            if (res) {
                if (res?.status === 1) {
                    if (!MfgID) {
                        const filteredArray = res?.UserData.map(obj => {
                            const filteredObj = {};
                            for (const key of filtersKey) {
                                if (obj.hasOwnProperty(key)) {
                                    filteredObj[key] = obj[key];
                                }
                            }
                            return filteredObj;
                        });
                        if (serach) {
                            setManufacturingArray(filteredArray);
                        } else {
                            setManufacturingArray(prevData => [...prevData, ...filteredArray]);
                        }
                        setTotalRow(res?.TotalRaw);
                        setTotalPage(res?.TotalPage);
                        setTotalPageRange(res?.PageRang);
                    } else {
                        changeTab(0);
                        setSingleData(res?.UserData);
                    }
                    setLoader(false);
                } else {
                    setLoader(false);
                    setSankbar(true);
                    setSankbarText(res?.message);
                    setSankbarColor("error");
                };
            };
        });
    };

    const onGlobalFilterChange = (value) => {
        setFilterValue(value);
        getManufacturing(null, 1, value);
    };

    const MfgViewHeader = () => {
        return (
            <div className='masterform'>
                <div className='input'>
                    <InputTypeText
                        keyfilter=''
                        type="search"
                        icon={<FontAwesomeIcon icon={faMagnifyingGlass} />}
                        value={FilterValue}
                        changeEvent={(value) => onGlobalFilterChange(value)}
                        name="Search"
                        id="search"
                    />
                </div>

                <div className='inputFilter'>
                    <div className='icons'>
                        <Tooltip target=".icon" position='top' />

                        <div className={!GridandListView ? 'icon active' : 'icon'} data-pr-tooltip="Grid view" data-pr-position="top" onClick={() => { setGridandListView(false); setManufacturingArray([]); setPage(1); setSize(25); getManufacturing(); }}>
                            <svg aria-hidden="true"><use xlinkHref="#grid_view"></use></svg>
                        </div>

                        <div className={GridandListView ? 'icon active' : 'icon'} data-pr-tooltip="List view" data-pr-position="top" onClick={() => { setGridandListView(true); setManufacturingArray([]); setPage(1); setSize(25); getManufacturing(); }}>
                            <svg aria-hidden="true"><use xlinkHref="#list_view"></use></svg>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    const imagesTag = (data) => {
        return (
            <div className={`${data.ImgLink == "" ? "defaulthover" : ""} image `}>
                {!data.ImgLink ? <div className='defultimage' style={{ WebkitMask: `url(${noimage})  center center / contain no-repeat` }}></div> :
                    <Image id='Image_zoom' src={data.ImgLink} zoomSrc={data.ImgLink} preview alt='image' />}
            </div>
        );
    };

    const items = (data) => ([
        {
            label: `Update`,
            icon: "",
            command: () => {
                if (isUpdate) {
                    getManufacturing(data.MfgID)
                    setManufacturingId(0);
                } else {
                    setSankbar(true);
                    setSankbarText('You are not allowed!');
                    setSankbarColor("warning");
                }
            },
        }, {
            label: "Delete",
            icon: <svg className="icon" aria-hidden="true"><use xlinkHref="#red_delete"></use></svg>,
            command: () => {
                if (isDelete) {
                    setManufacturingId(data?.MfgID)
                    setConfirmModel(true);
                    setConfirmText('Are you sure this product delete?');
                } else {
                    setSankbar(true);
                    setConfirmModel(false);
                    setSankbarText('You are not allowed!');
                    setSankbarColor("warning");
                }
            },
        }
    ]);

    const buttonTemplate = (data) => (
        <div className='actionbutton'>
            <SplitButton dropdownIcon={<svg className="icon" aria-hidden="true"><use xlinkHref="#table_dot"></use></svg>} className="p-button-sm" size="small" model={items(data)} />
        </div>
    );

    const deleteMFG = () => {
        Post('MfgIss/MfgIDelete', { "MfgID": manufacturingId ? manufacturingId : '' }).then((res) => {
            if (res?.status === 1) {
                setSankbar(true);
                setSankbarText(res?.message);
                setSankbarColor("success");
                getManufacturing()
            } else {
                setSankbar(true);
                setSankbarText(res?.message);
                setSankbarColor("error");
            }
        })
    }

    return (
        <div id='MfgTrnIssueView' >

            {GridandListView ?
                <TableGrid
                    tableArray={manufacturingArray}
                    columns={ManufacturingColumns}
                    header={MfgViewHeader}
                    imagesTag={imagesTag}
                    actionButton={buttonTemplate}
                    totalRow={totalRow}
                    totalPage={totalPage}
                    totalPageRange={totalPageRange}
                    page={page}
                    size={size}
                    setPage={(value) => setPage(value)}
                    setSize={(value) => setSize(value)}

                />
                : <div className='girdView'>
                    {MfgViewHeader()}
                    <div className='maingrid'>
                        {manufacturingArray.length > 0 && manufacturingArray.map((Item, index) => {
                            return (
                                <div className='boxGrid' key={index}>
                                    <div className='images'>
                                        <div className='icon'>
                                            {buttonTemplate(Item)}
                                        </div>

                                        {imagesTag(Item)}
                                    </div>

                                    <div className='body'>
                                        <div className='title'>
                                            <div className='price_box'>
                                                {Item?.FullName ?
                                                    <h5> {Capitalize(Item?.FullName)}</h5> : ""}
                                            </div>
                                        </div>

                                        <div className='subTitle'>
                                            {Item?.PartyID ?
                                                <React.Fragment>
                                                    <label>Party id </label>
                                                    <p><span> :-</span><span> {Item?.PartyID} </span></p>
                                                </React.Fragment> : ""}

                                            {Item?.MfgID ?
                                                <React.Fragment>
                                                    <label>MFG id </label>
                                                    <p><span> :-</span><span> {Item?.MfgID} </span></p>
                                                </React.Fragment> : ""}

                                            {Item?.I_Date ?
                                                <React.Fragment>
                                                    <label>Date</label>
                                                    <p><span> :-</span><span> {Item?.I_Date} </span></p>
                                                </React.Fragment> : ""}

                                            {Item?.I_Time ?
                                                <React.Fragment>
                                                    <label>Time</label>
                                                    <p><span> :-</span><span>  {Item?.I_Time} </span></p>
                                                </React.Fragment> : ""}
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>}

            <ConfirmDialogModal
                open={confirmModel}
                onHide={(value) => setConfirmModel(value)}
                onclick={() => { checkpassword(true) }}
                massege={confirmText}
            />

            <PasswordBox
                open={verifyPassword}
                password={getPassword}
                verifyPassword={(value) => setVerifyPassword(value)}
                buttonCheck={() => { deleteMFG() }}
            />

            <SnackbarNotification
                open={sankbar}
                text={sankbarText}
                color={sankbarColor}
                sankbar={() => setSankbar()}
            />

            {loader ? <Loader /> : ""}
        </div>
    )
}

export default Manufacturingtransaction;