import React from 'react'
import nodata from "../../assets/Img/nofound.svg"
import "./Nodataavailable.scss"

function Nodataavailable() {
    return (
        <div>

            <div className='Nodataavailable'>

                <div className='imagesnodata' style={{ background: `url(${nodata})  center center / contain no-repeat` }}></div>
                <p className="text-center font-bold text-darkblue-500 mb-2">Ups!... no results found</p>
                <p className="text-gray-700 text-center">Please try another search</p>
            </div>
        </div>
    )
}

export default Nodataavailable