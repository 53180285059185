import React, { Suspense, useEffect, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import Header from "../Header/header";
import Sidebar from "../Sidebar/sidebar";
import Page500 from "../commanComponet/500page/Page500";

function Layout() {
    const [siderOpen, setSidebarOpen] = useState(false);
    setInterval(() => {
        if (window.location.pathname === "/" || window.location.pathname === "/login") {
            sessionStorage.removeItem("header");
            localStorage.removeItem('userData');
            localStorage.removeItem("login");
            localStorage.removeItem("ID");
        }
    }, 1);

    return (
        <React.Fragment>
                <Header setSidebarOpen={(value) => setSidebarOpen(value)} siderOpen={siderOpen} />
            <div className="dashboard">
                <Sidebar siderOpen={siderOpen} setSidebarOpen={(value) => setSidebarOpen(value)} />
                <div className={`pages ${siderOpen ? "DashboardOpen" : "DashboardColse"}`}>
                    <div className="pageData">
                        <Suspense><Outlet /></Suspense>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}
export default Layout;