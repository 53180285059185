import CryptoJS from 'crypto-js';

function FullFormatDate(inputDate) {
  const date = new Date(inputDate);

  if (!date || isNaN(date.getTime())) {
    return 'Invalid Date';
  }

  const day = date.getDate();
  const month = date.getMonth() + 1;
  const year = date.getFullYear();

  const formattedDate = `${day.toString().padStart(2, '0')}-${month.toString().padStart(2, '0')}-${year}`;
  return formattedDate;
}

function FormatDate(inputDate, format) {
  let date;
  if (!inputDate.includes('T')) {
    if (inputDate.includes('-')) {
      const dateParts = inputDate.split('-');
      if (dateParts.length === 3) {
        const year = dateParts[2];
        const month = dateParts[1];
        const day = dateParts[0];
        date = new Date(`${year}-${month}-${day}`);
      }
    } else {
      const dateParts = inputDate.split('/');
      if (dateParts.length === 3) {
        const year = dateParts[2];
        const month = dateParts[1];
        const day = dateParts[0];
        date = new Date(`${year}-${month}-${day}`);
      }
    }
  } else if (inputDate.includes('T')) {
    date = new Date(inputDate);
  }
  if (!date || isNaN(date.getTime())) {
    return 'Invalid Date';
  }

  const options = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
  };

  const formattedDate = new Intl.DateTimeFormat('en-IN', options).format(date);

  return format
    .replace('YYYY', formattedDate.slice(6, 10))
    .replace('MM', formattedDate.slice(3, 5))
    .replace('DD', formattedDate.slice(0, 2))
    .replace('HH', formattedDate.slice(11, 13))
    .replace('mm', formattedDate.slice(14, 16))
    .replace('ss', formattedDate.slice(17, 19));
}

function Dateformat(inputDate) {
  // Parse the date string into a Date object
  const dateParts = inputDate.split('-');
  const parsedDate = new Date(
    parseInt(dateParts[2], 10),
    parseInt(dateParts[1], 10) - 1,
    parseInt(dateParts[0], 10)
  );

  // Format date and time components
  const formattedDate = parsedDate.toLocaleDateString('en-US', {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
  });
  const formattedTime = parsedDate.toLocaleTimeString('en-US', {
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    timeZoneName: 'short',
  });

  // Combine date and time components
  const finalFormattedDate = `${formattedDate} ${formattedTime} GMT+0530 (India Standard Time)`;

  return finalFormattedDate;
};

function PlusDate(value) {
  var currentDate = new Date();
  var currentDateFormate = new Date(currentDate);
  currentDateFormate.setDate(currentDate.getDate() + Number(value));
  var dateObject = new Date(currentDateFormate);
  var year = dateObject.getFullYear();
  var month = ("0" + (dateObject.getMonth() + 1)).slice(-2);
  var day = ("0" + dateObject.getDate()).slice(-2);
  var formattedDate = `${day}-${month}-${year}`;

  return formattedDate;
}

function MinusDate(value) {
  var currentDate = new Date();
  var currentDateFormate = new Date(currentDate);
  currentDateFormate.setDate(currentDate.getDate() - Number(value));
  var dateObject = new Date(currentDateFormate);
  var year = dateObject.getFullYear();
  var month = ("0" + (dateObject.getMonth() + 1)).slice(-2);
  var day = ("0" + dateObject.getDate()).slice(-2);
  var formattedDate = `${day}-${month}-${year}`;

  return formattedDate;
}

function AddharCardFormant(value) {
  if (value !== null) {
    let no = String(value).replace(/\s/g, '');
    if (no.length <= 12) {
      no = no.replace(/(.{4})/g, '$1 ');
    }
    return no;
  }
}

function InvalidAddharCard(length, value) {
  let Invalid = false;
  if (value) {
    if (length === Number(String(value).length - 1)) {
      Invalid = true;
    }
  } else {
    Invalid = false;
  }
  return Invalid;
}

function InvalidNumber(length, code, value) {
  let Invalid = false;
  if (value !== null) {
    var inputLength = (Number(length - 1) - Number(code.toString().length));
    if (inputLength === String(value).length) {
      Invalid = true;
    }
  }
  return Invalid;
}

function InvalidDropDown(value) {
  let DropDown = false;
  if (value !== null && value !== '') {
    DropDown = true;
  }
  return DropDown;
}

function InvalidEmail(email) {
  let EmailValid = false;
  if (email !== null) {
    var Email = email.match(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
    if (Email) {
      EmailValid = true;
    }
  }
  return EmailValid;
}

function InvalidPanCardNo(panCardNo) {
  let PancardValid = false;
  let regex = new RegExp(/^[A-Z]{5}[0-9]{4}[A-Z]{1}$/);

  if (regex.test(panCardNo) === true) {
    PancardValid = true;
  }
  return PancardValid;
}

function decryptValue(encryptedResult) {
  const secretKey = "m0987poiuytrewqasdfghjkl1234xcvbn";
  const decrypted = CryptoJS.AES.decrypt(encryptedResult, secretKey).toString(CryptoJS.enc.Utf8);
  return decrypted;
}

// Text Print
const Capitalize = (value) => {
  if (value) {
    return value?.charAt(0).toUpperCase() + value?.slice(1).toLowerCase();
  } else {
    return value;
  }
};

const UpperCase = (value) => {
  if (value) {
    return value.toUpperCase();
  } else {
    return value;
  }
};


function capitalizeFullName(fullName) {
  const words = fullName.trim().split(/\s+/);

  // Capitalize the first letter of each word
  const capitalizedWords = words.map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase());

  const capitalizedFullName = capitalizedWords.join(' ');

  return capitalizedFullName;
};

export { PlusDate, FormatDate, FullFormatDate, InvalidNumber, InvalidDropDown, Dateformat, InvalidEmail, InvalidPanCardNo, MinusDate, AddharCardFormant, InvalidAddharCard, decryptValue, Capitalize, UpperCase, capitalizeFullName };