import React, { useEffect, useState } from "react";
import "./login.scss";
import logo from "./../assets/Logo/logo 25.png";
import logofull from "./../assets/Img/login_image.png";
import logoresponsive from "./../assets/Logo/Logo 38.svg";
import { useNavigate } from "react-router-dom";
import InputTypeText from "../commanComponet/InputTypeText/InputTypeText.js";
import { Button } from "primereact/button";
import InputTypePassword from "../commanComponet/InputTypePassword/InputTypePassword.js";
import SnackbarNotification from "../commanComponet/Snackbar/SnackbarNotification.js";
import { Get, Post } from "../commanAPI/commanAPI.js";
import Loader from "../commanComponet/Loader/loader.js";

function Login() {
  const [username, setUsername] = useState('');
  const [usernameVaild, setUsernameVaild] = useState(false);
  const [password, setPassword] = useState('');
  const [passwordVaild, setPasswordVaild] = useState(false);

  // Loader
  const [loader, setLoader] = useState(false);

  // SankBar
  const [sankbar, setSankbar] = useState(false);
  const [sankbarText, setSankbarText] = useState("");
  const [sankbarColor, setSankbarColor] = useState("");

  const navigate = useNavigate();

  useEffect(() => {
    if (window.location.pathname === "/" || window.location.pathname === "/login") {
      sessionStorage.removeItem("header");
      sessionStorage.removeItem("FRM_Code");
      localStorage.removeItem('userData');
      localStorage.removeItem("login");
      localStorage.removeItem("ID");
      localStorage.removeItem("Error");
      navigate('/login');
    }
  }, [window.location.pathname]);

  const Login = () => {
    if (username === "" || username === null || username === undefined) {
      setUsernameVaild(true);
      setSankbar(true);
      setSankbarText('Kindly provide both your username for authentication!');
      setSankbarColor("warning");
      localStorage.setItem("login", false);
      return;
    } else if (password === "" || password === null || password === undefined) {
      setPasswordVaild(true);
      setSankbar(true);
      setSankbarText('Kindly provide both your password for authentication!');
      setSankbarColor("warning");
      localStorage.setItem("login", false);
      return;
    } else {
      setLoader(true);
      Post("Login", { username: username, password: password, }).then((res) => {
        if (res) {
          if (res.status === 1) {
            if (Object.keys(res).length > 0) {
              localStorage.setItem("ID", res.Token);
              localStorage.setItem("userData", JSON.stringify(res.UserData));
              sessionStorage.setItem("header", JSON.stringify(res.Header));
              localStorage.setItem("login", true);
              navigate(`/mdi/dashboard`);

              var country = JSON.parse(sessionStorage.getItem("country"));
              if (country === undefined || country === null) {
                Get('ApiCountry').then((res) => {
                  if (res) {
                    if (res.status === 1) {
                      if (res.UserData.length > 0) {
                        sessionStorage.setItem("country", JSON.stringify(res.UserData));
                        setLoader(false);
                      };
                    } else {
                      setSankbar(true);
                      setSankbarText(res.message);
                      setSankbarColor("error");
                    }
                  };
                });
              };

            } else {
              setLoader(false);
              localStorage.removeItem('userData');
              localStorage.removeItem("login");
              localStorage.removeItem("ID");
              localStorage.setItem("login", false);
              setSankbar(true);
              setSankbarText(res.message);
              setSankbarColor("error");
            }
          } else {
            setLoader(false);
            localStorage.removeItem('userData');
            localStorage.removeItem("login");
            localStorage.removeItem("ID");
            localStorage.setItem("login", false);
            setSankbar(true);
            setSankbarText(res.message);
            setSankbarColor("error");
          };
        } else {
          setLoader(false);
          localStorage.removeItem('userData');
          localStorage.removeItem("login");
          localStorage.removeItem("ID");
          localStorage.setItem("login", false);
          setSankbar(true);
          setSankbarText(res?.message);
          setSankbarColor("error");
        }
      });
    }
  };

  const onkeydown = (e) => {
    if (e.key === "Enter" || e.keyCode === 13) {
      Login();
    };
  };

  return (
    <div className="login-container">
      <div className="login_frame">
        <div className="long_logo">
          <div className="Welcome_text">
            <h2> Hi, Welcome back </h2>
          </div>
          <div className="logo_img">
            <img src={logofull} />
          </div>
        </div>
      </div>

      <div className="login_from">
        <div className="login">
          <div className="logo_img">
            <img src={logoresponsive} />
          </div>

          <div className="logo_img1">
            <img src={logo} />
          </div>

          <div className="login_text">
            <h3>Sign in to Minimal</h3>
          </div>

          <div className="from">
            <InputTypeText
              keyfilter='alphanum'
              value={username}
              className={usernameVaild}
              changeEvent={(value) => setUsername(value)}
              name="User Name"
              id="username"
              autoFocus={true}
              tabIndex={1}
            />

            <InputTypePassword
              changeEvent={(value) => setPassword(value)}
              value={password}
              className={passwordVaild}
              name={"Password"}
              id={"password"}
              icon={false}
              keyDown={(e) => onkeydown(e)}
              tabIndex={2}
            />

            <Button
              label="Login"
              severity="secondary"
              type="submit"
              onClick={() => Login()}
            />
          </div>
        </div>
        <div className="version">Version 1.0.0.0</div>
      </div>

      {loader ? <Loader /> : ""}

      <SnackbarNotification open={sankbar} text={sankbarText} color={sankbarColor} sankbar={() => setSankbar()} />

    </div>
  );
}

export default Login;