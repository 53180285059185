import React, { useEffect, useRef } from 'react';
import './InputTypePassword.scss';
import { Password } from 'primereact/password';

function InputTypePassword(props) {
    const value = props.value;
    const className = props.className;
    const id = props.id;
    const name = props.name;
    const icon = props.icon;
    const autoFocus = props.autoFocus;
    const tabIndex = props.tabIndex;

    const InputPasswordRef = useRef(null);

    useEffect(() => {
        if (className) {
            InputPasswordRef.current.focus()
        }
    }, [className]);

    return (
        <div className={!icon ? "password" : "p-inputgroup"}>
            {!icon ? "" :
                <span  className={className ? `p-inputgroup-addon p-invalid}` : `p-inputgroup-addon`}>
                    {icon}
                </span>
            }
            <span className="p-float-label">
                <Password
                    ref={InputPasswordRef}
                    className={className ? "password p-invalid" : "password"}
                    id={id}
                    value={value}
                    onChange={(e) => props.changeEvent(e.target.value, id)}
                    toggleMask
                    feedback={false}
                    onKeyDown={(e) => props.keyDown !== undefined ? props.keyDown(e) : ''}
                    // autoFocus={autoFocus}
                    // tabIndex={tabIndex}
                />
                <label htmlFor={id}>{name}</label>
            </span>
        </div>
    );
}

export default InputTypePassword;
