import React, { useEffect, useState } from 'react'
import "./ManufacturingIssueEntry.scss"
import { decryptValue } from '../../../commanComponet/GlobalFunction/globalFunction';
import { Post } from '../../../commanAPI/commanAPI';
import PasswordBox from '../../../commanComponet/PasswordBox/PasswordBox';
import SnackbarNotification from '../../../commanComponet/Snackbar/SnackbarNotification';
import SelectDropdown from '../../../commanComponet/SelectDropdown/SelectDropdown';
import { Button } from 'primereact/button';
import ImageUpload from '../../../commanComponet/ImageUpload/ImageUpload';
import Loader from '../../../commanComponet/Loader/loader';
import TableGrid from '../../../commanComponet/TableGrid/TableGrid';
import { ComboFill } from '../../../commanComponet/GlobalAPI/GlobalAPI';
import InputTypeNumber from '../../../commanComponet/InputTypeNumber/InputTypeNumber';
import { SplitButton } from 'primereact/splitbutton';
import ConfirmDialogModal from '../../../commanComponet/ConfirmDialogModal/ConfirmDialogModal';

function ManufacturingIssueEntry({ SingleData, setSingleData }) {
    const userData = JSON.parse(localStorage.getItem("userData"));

    // Loader
    const [loader, setLoader] = useState(false);

    // SankBar
    const [sankbar, setSankbar] = useState(false);
    const [sankbarText, setSankbarText] = useState("");
    const [sankbarColor, setSankbarColor] = useState("");

    // Password check
    const [verifyPassword, setVerifyPassword] = useState(false);
    const [getPassword, setGetPassword] = useState('');
    const [isInsert, setIsInsert] = useState(true);
    const [isUpdate, setIsUpdate] = useState(false);
    const [isDelete, setIsDelete] = useState(false);

    // Coman COL
    const [COL1, setCOL1] = useState('');
    const [COL2, setCOL2] = useState('');
    const [COL3, setCOL3] = useState('');
    const [COL4, setCOL4] = useState('');
    const [COL5, setCOL5] = useState('');

    // Photo
    const [bineryFile, setBineryFile] = useState({});
    const [photolink, setPhotolink] = useState('');
    const [photoNo, setPhotoNo] = useState('');

    // Frist Table colums
    const [manufacturingColumns, setManufacturingColumns] = useState([]);
    const [manufacturingArray, setManufacturingArray] = useState([]);

    // second Table colums
    const [manufacturingStockColumns, setManufacturingStockColumns] = useState([]);
    const [manufacturingStockArray, setManufacturingStockArray] = useState([]);
    const [manufacturingStockSelected, setManufacturingStockSelected] = useState(null);
    const [manufacturingStockSelectedPcs, setManufacturingStockSelectedPcs] = useState(0);
    const [manufacturingStockSelectedWeight, setManufacturingStockSelectedWeight] = useState(0);

    // party data
    const [partyData, setPartyData] = useState([]);
    const [partyValue, setPartyValue] = useState(null);
    const [partyValueValid, setPartyValueValid] = useState(false);

    // item
    const [itemValue, setItemValue] = useState(null);
    const [itemValueVaild, setItemValueVaild] = useState(false);
    const [itemOption, setItemOption] = useState([]);

    // order
    const [orderValue, setOrderValue] = useState(null);
    const [orderValueVaild, setOrderValueVaild] = useState(false);
    const [orderOption, setOrderOption] = useState([]);

    // material
    const [materialValue, setMaterialValue] = useState(null);
    const [materialId, setMaterialId] = useState(0);
    const [materialValueVaild, setMaterialValueVaild] = useState(false);
    const [materialOption, setMaterialOption] = useState([]);

    // Pcs
    const [pcs, setPcs] = useState(null);
    const [pcsValid, setPcsValid] = useState(false);

    // weight
    const [weight, setWeight] = useState(null);
    const [weightValid, setWeightValid] = useState(false);

    // refresh Key
    const [refreshKey, setRefreshKey] = useState(0);

    // Manufacturing stock
    const [manufacturingStockPcs, setManufacturingStockPcs] = useState(0);
    const [manufacturingStockWeight, setManufacturingStockWeight] = useState(0);

    // manufacturing ID
    const [manufacturingID, setManufacturingID] = useState(0);
    const [manufacturingOrderID, setManufacturingOrderID] = useState(0);
    const [manufacturingOrderDetID, setManufacturingOrderDetID] = useState(0);

    // Confirmation
    const [confirmModel, setConfirmModel] = useState(false);
    const [confirmText, setConfirmText] = useState('');

    // insertupdate
    const [isDeteleUpdate, setIsDeleteUpdate] = useState(false);

    let manufacturingIssue = false;
    useEffect(() => {
        if (!manufacturingIssue) {
            setLoader(true);
            checkpassword(false);
            manufacturingIssue = true;
        }
    }, []);

    const checkpassword = (value) => {
        Post("UserPer/GetFormPer", { USRGRP: userData?.UGROUP, F_NAME: sessionStorage.getItem('FRM_Code') }).then((res) => {
            if (res) {
                if (res?.status === 1) {
                    setIsInsert(res?.UserData.ISINS);
                    setIsUpdate(res?.UserData.ISUPD);
                    setIsDelete(res?.UserData.ISDEL);
                    if (res?.UserData.ISPASS) {
                        setVerifyPassword(value);
                        const password = decryptValue(res?.UserData.PASS);
                        setGetPassword(password);
                    } else {
                        if (value) {
                            if (!isDeteleUpdate) {
                                if (res?.UserData.ISINS) {
                                    onSaveManufacturing();
                                } else {
                                    setSankbar(true);
                                    setSankbarText('You are not allowed!');
                                    setSankbarColor("warning");
                                    return;
                                }
                            } else {
                                if (res?.UserData.ISDEL) {
                                    materialDelete();
                                } else {
                                    setSankbar(true);
                                    setSankbarText('You are not allowed!');
                                    setSankbarColor("warning");
                                }
                            };
                        };
                    };

                    setCOL1(res?.UserData.COL1);
                    setCOL2(res?.UserData.COL2);
                    setCOL3(res?.UserData.COL3);
                    setCOL4(res?.UserData.COL4);
                    setCOL5(res?.UserData.COL5);

                    if (!value) {
                        tableDataCode("manufacturingIssue");

                        if (SingleData && SingleData.length > 0) {
                            for (let c = 0; c < SingleData.length; c++) {
                                if (SingleData[c].MfgID) {
                                    setManufacturingID(SingleData[c].MfgID);
                                }
                                if (SingleData[c].PartyID) {
                                    partyselection(res?.UserData.COL1, SingleData[c].PartyID);
                                };

                                if (SingleData[c].OrderID) {
                                    orderselection(SingleData[c].OrderID);
                                };

                                if (SingleData[c].SubData && SingleData[c].SubData.length > 0) {
                                    for (let s = 0; s < SingleData[c].SubData.length; s++) {
                                        if (SingleData[c].SubData[s]) {
                                            if (typeof SingleData[c].SubData[s].I_Weight === 'number') {
                                                SingleData[c].SubData[s].I_Weight = SingleData[c].SubData[s].I_Weight + ' ' + 'gm';
                                            }
                                        };
                                    };
                                    setManufacturingArray(SingleData[c].SubData);
                                };
                            };
                        } else {
                            partyselection(res?.UserData.COL1);
                            materialselection();
                            itemselection();
                        };

                        tableDataCode("manufacturingStock");
                    };
                } else {
                    setLoader(false);
                    setSankbar(true);
                    setSankbarText(res?.message);
                    setSankbarColor("error");
                };
            };
        });
    };

    const tableDataCode = (code) => {
        Post("ViewPara/GetFormWise", {
            VIEWNAME: code
        }).then((res) => {
            if (res) {
                if (res?.status === 1) {
                    if (res?.UserData.length > 0) {
                        if (code === 'manufacturingIssue') {
                            setManufacturingColumns(res?.UserData);
                        } else {
                            setManufacturingStockColumns(res?.UserData);
                        };
                        setLoader(false);
                    } else {
                        setLoader(false);
                        setSankbar(true);
                        setSankbarText(res?.message);
                        setSankbarColor("warning");
                    };
                } else {
                    setLoader(false);
                    setSankbar(true);
                    setSankbarText(res.message);
                    setSankbarColor("error");
                }
            }
        });
    };

    const partyselection = (id, value) => {
        ComboFill("PARMAST", id ? id : COL1).then((res) => {
            if (res) {
                if (res?.status === 1) {
                    setPartyData(res?.UserData);
                    if (value) {
                        const party = res?.UserData.filter((item) => item.ParID === Number(value));
                        if (party.length > 0) {
                            setPartyValue(party[0]);
                            setPartyValueValid(false);
                            partyStockData(party[0]);
                        } else {
                            setPartyValue(null);
                            setPartyValueValid(true);
                        }
                    }
                } else {
                    setLoader(false);
                    setSankbar(true);
                    setSankbarText(res?.message);
                    setSankbarColor("error");
                };
            };
        });
    };

    const materialselection = (value) => {
        ComboFill("MFGIRMET").then((res) => {
            if (res) {
                if (res?.status === 1) {
                    if (res?.UserData.length > 0) {
                        if (!value) {
                            setMaterialOption([]);
                            if (manufacturingArray.length > 0) {
                                const filteredMaterials = res?.UserData.filter(userDataItem => {
                                    return !manufacturingArray.some(manufacturingItem => manufacturingItem.MaterialId === userDataItem.MaterialId);
                                });
                                setMaterialOption(filteredMaterials);
                            } else {
                                setMaterialOption(res?.UserData);
                            }
                        } else {
                            setMaterialOption(res?.UserData);
                            for (let c = 0; c < res?.UserData.length; c++) {
                                if (res?.UserData[c].MaterialId === Number(value)) {
                                    setMaterialValue(res?.UserData[c]);
                                    setMaterialValueVaild(false);

                                    if (manufacturingStockArray || res?.UserData[c]) {
                                        for (let c = 0; c < manufacturingStockArray.length; c++) {
                                            if (manufacturingStockArray[c].MaterialId === Number(value)) {
                                                setManufacturingStockSelected(manufacturingStockArray[c]);
                                                setManufacturingStockSelectedPcs(manufacturingStockArray[c].StockPcs);
                                                setManufacturingStockSelectedWeight(manufacturingStockArray[c].StockWeight);
                                            };
                                        };
                                    };
                                    setManufacturingStockPcs(res?.UserData[c] ? res?.UserData[c].Pcs : 0);
                                    setManufacturingStockWeight(res?.UserData[c] ? res?.UserData[c].Weight : 0);
                                }
                            }
                        }
                        setLoader(false);
                    } else {
                        setLoader(false);
                        setSankbar(true);
                        setSankbarText(res?.message);
                        setSankbarColor("warning");
                    }
                } else {
                    setLoader(false);
                    setSankbar(true);
                    setSankbarText(res?.message);
                    setSankbarColor("error");
                }
            }
        });
    };

    const itemselection = (value) => {
        ComboFill("ITEMMAST").then((res) => {
            if (res) {
                if (res?.status === 1) {
                    setItemOption(res?.UserData);
                    if (value) {
                        for (let c = 0; c < res?.UserData.length; c++) {
                            if (res?.UserData[c].ItemId === Number(value)) {
                                setItemValue(res?.UserData[c]);
                                setItemValueVaild(false);
                                setLoader(false);
                            }
                        }
                    }
                } else {
                    setLoader(false);
                    setSankbar(true);
                    setSankbarText(res?.message);
                    setSankbarColor("error");
                };
            };
        });
    };

    const orderselection = (value) => {
        ComboFill("ORDERLIST").then((res) => {
            if (res) {
                if (res?.status === 1) {
                    setOrderOption(res?.UserData);
                    if (value) {
                        const order = res?.UserData.filter((item) => item.OrderID === Number(value));
                        if (order.length > 0) {
                            setOrderValue(order[0]);

                            if (order[0].ItemId) {
                                itemselection(order[0].ItemId)
                            };

                            if (order[0].Photolink) {
                                setBineryFile({});
                                setPhotolink(order[0].Photolink);
                            };

                            if (order[0].ImgNo) {
                                setPhotoNo(order[0].ImgNo);
                            };

                            if (order[0].OrderID) {
                                setManufacturingOrderID(order[0].OrderID);
                            };

                            if (order[0].OrderDetID) {
                                setManufacturingOrderDetID(order[0].OrderDetID);
                            };

                            setOrderValueVaild(false);
                        } else {
                            setOrderValue(null);
                            setOrderValueVaild(true);
                        };
                    };
                } else {
                    setLoader(false);
                    setSankbar(true);
                    setSankbarText(res?.message);
                    setSankbarColor("error");
                };
            };
        });
    };

    const itemTemplate = (item) => {
        return (
            <div className="p-d-flex p-ai-center">
                <img src={item.Photolink} alt={item.ItemName} style={{ width: '30px', marginRight: '10px' }} />
                <div>{item.OrderNo}</div>
            </div>
        );
    };

    const checkData = () => {
        if (!partyValue || typeof partyValue !== 'object') {
            setPartyValueValid(true);
            setSankbar(true);
            setSankbarText("Please select party!");
            setSankbarColor("warning");
        } else if (!materialValue || typeof materialValue !== 'object') {
            setMaterialValueVaild(true);
            setSankbar(true);
            setSankbarText("Please select material!");
            setSankbarColor("warning");
        } else if (!pcs) {
            setPcsValid(true);
            setSankbar(true);
            setSankbarText("Please select pcs!");
            setSankbarColor("warning");
        } else if (!weight) {
            setWeightValid(true);
            setSankbar(true);
            setSankbarText("Please select weight!");
            setSankbarColor("warning");
        } else {
            onClickPluseData();
        };
    };

    const onClickPluseData = () => {
        if (manufacturingArray.length > 0) {
            for (let c = 0; c < manufacturingArray.length; c++) {
                if (typeof materialValue === 'object') {
                    if (manufacturingArray[c].MaterialId === materialValue.MaterialId) {
                        manufacturingArray[c].MaterialName = materialValue ? typeof materialValue === 'object' ? materialValue.MaterialName : "" : "";
                        manufacturingArray[c].MaterialId = materialValue ? typeof materialValue === 'object' ? materialValue.MaterialId : "" : "";
                        manufacturingArray[c].I_Pcs = pcs;
                        manufacturingArray[c].I_Weight = weight + " " + "gm";
                        manufacturingArray[c].button = false;
                        setManufacturingArray(manufacturingArray);
                        setMaterialOption([]);
                        setMaterialValue(null);
                        setMaterialValueVaild(true);
                        setPcs("");
                        setPcsValid(false);
                        setWeight("");
                        setWeightValid(false);
                        setManufacturingStockSelected(null);
                        setManufacturingStockPcs(0);
                        setManufacturingStockWeight(0);
                        setManufacturingStockSelectedPcs(0);
                        setManufacturingStockSelectedWeight(0);
                        materialselection();
                        return;
                    } else {
                        setManufacturingArray([...manufacturingArray, {
                            MaterialName: materialValue ? typeof materialValue === 'object' ? materialValue.MaterialName : "" : "",
                            MaterialId: materialValue ? typeof materialValue === 'object' ? materialValue.MaterialId : "" : "",
                            I_Pcs: pcs,
                            I_Weight: weight + " " + "gm",
                            button: false
                        }]);
                    }
                }
            }
        } else {
            setManufacturingArray([...manufacturingArray, {
                MaterialName: materialValue ? typeof materialValue === 'object' ? materialValue.MaterialName : "" : "",
                MaterialId: materialValue ? typeof materialValue === 'object' ? materialValue.MaterialId : "" : "",
                I_Pcs: pcs,
                I_Weight: weight + " " + "gm",
                button: false
            }]);
        }

        setMaterialOption([]);
        setMaterialValue(null);
        setMaterialValueVaild(true);
        setPcs("");
        setPcsValid(false);
        setWeight("");
        setWeightValid(false);
        setManufacturingStockSelected(null);
        setManufacturingStockPcs(0);
        setManufacturingStockWeight(0);
        setManufacturingStockSelectedPcs(0);
        setManufacturingStockSelectedWeight(0);
        materialselection();
    };

    const rowDoubleClick = (data) => {
        if (!data.button) {
            console.log(data);
            if (data.MaterialId) {
                materialselection(data.MaterialId);
                setPcs(data.I_Pcs);
                setPcsValid(false);
                if (typeof data.I_Weight === 'string') {
                    setWeight(data.I_Weight ? data.I_Weight.split(" ")[0] : '');
                } else {
                    setWeight(data.I_Weight ? data.I_Weight : '');
                }
                setWeightValid(false);
                setIsDeleteUpdate(false);
            } else {
                setSankbar(true);
                setSankbarText('Material has been deleted!');
                setSankbarColor("warning");
            }
        };
    };

    const materialDelete = () => {
        const manufacturingDeleteData = manufacturingArray.filter((item) => item.MaterialId !== Number(materialId));
        setManufacturingArray(manufacturingDeleteData);
    };

    const items = (data) => ([
        {
            label: "Update",
            icon: <svg className="icon" aria-hidden="true"><use xlinkHref="#update"></use></svg>,
            command: () => {
                if (isUpdate) {
                    if (data.MaterialId) {
                        materialselection(data.MaterialId);
                        setPcs(data.I_Pcs);
                        setPcsValid(false);
                        if (typeof data.I_Weight === 'string') {
                            setWeight(data.I_Weight ? data.I_Weight.split(" ")[0] : '');
                        } else {
                            setWeight(data.I_Weight ? data.I_Weight : '');
                        }
                        setWeightValid(false);
                        setIsDeleteUpdate(false);
                    } else {
                        setSankbar(true);
                        setSankbarText('Material has been deleted!');
                        setSankbarColor("warning");
                    }
                } else {
                    setSankbar(true);
                    setSankbarText('You are not allowed!');
                    setSankbarColor("warning");
                }
            },
        },
        {
            label: "Delete",
            icon: <svg className="icon" aria-hidden="true"><use xlinkHref="#red_delete"></use></svg>,
            command: () => {
                if (isDelete) {
                    setMaterialId(data.MaterialId);
                    setIsDeleteUpdate(true);
                    setConfirmModel(true);
                    setConfirmText('Are you sure this delete!');
                } else {
                    setSankbar(true);
                    setSankbarText('You are not allowed!');
                    setSankbarColor("warning");
                }
            },
        }
    ]);

    const buttonTemplate = (data) => {
        return (
            <div className='actionbutton'>
                {data.button ?
                    <Button tabIndex={8} onClick={() => checkData()} icon={<svg className="icon" aria-hidden="true"><use xlinkHref="#add"></use></svg>} />
                    :
                    <SplitButton dropdownIcon={<svg className="icon" aria-hidden="true"><use xlinkHref="#table_dot"></use></svg>} className="p-button-sm" size="small" model={items(data)} />
                }
            </div>
        );
    };

    // onChange
    const onHandelchange = (value, name) => {
        if (name === 'partytype') {
            setPartyValue(value);
            if (typeof value === 'object') {
                setPartyValueValid(false);
                partyStockData(value);
            } else {
                setPartyValueValid(true);
                setManufacturingStockArray([]);
            };
        } else if (name === 'item') {
            setItemValue(value);
            if (typeof value === 'object') {
                setItemValueVaild(false);
            } else {
                setItemValueVaild(true);
            };
        } else if (name === 'order') {
            setOrderValue(value);
            if (typeof value === 'object') {
                setOrderValueVaild(false);
                if (value.ItemId) {
                    itemselection(value.ItemId)
                };

                if (value.Photolink) {
                    setBineryFile({});
                    setPhotolink(value.Photolink);
                };

                if (value.ImgNo) {
                    setPhotoNo(value.ImgNo);
                };

                if (value.OrderID) {
                    setManufacturingOrderID(value.OrderID);
                };

                if (value.OrderDetID) {
                    setManufacturingOrderDetID(value.OrderDetID);
                };
            } else {
                setOrderValueVaild(true);
                setItemValue(null);
                setItemValueVaild(false);
                setBineryFile({});
                setPhotolink("");
                setPhotoNo("");
                setManufacturingOrderID(0);
                setManufacturingOrderDetID(0);
            };
        } else if (name === 'material') {
            if (!partyValue || typeof partyValue !== 'object') {
                setPartyValueValid(true);
                setSankbar(true);
                setSankbarText("Please select Party!");
                setSankbarColor("warning");
                setRefreshKey(prevKey => prevKey + 1);
                return;
            };
            setMaterialValue(value);

            if (typeof value === 'object') {
                setMaterialValueVaild(false);
                if (manufacturingStockArray || value) {
                    for (let c = 0; c < manufacturingStockArray.length; c++) {
                        if (manufacturingStockArray[c].MaterialId === value.MaterialId) {
                            setManufacturingStockSelected(manufacturingStockArray[c]);
                            setManufacturingStockSelectedPcs(manufacturingStockArray[c].StockPcs);
                            setManufacturingStockSelectedWeight(manufacturingStockArray[c].StockWeight);
                        };
                    };
                };
                setManufacturingStockPcs(value ? value.Pcs : 0);
                setManufacturingStockWeight(value ? value.Weight : 0);
            } else {
                setMaterialValueVaild(true);
            };

        } else if (name === 'pcs') {
            if (manufacturingStockPcs >= value) {
                setPcs(value);
                if (value) {
                    setPcsValid(false);
                } else {
                    setPcsValid(true);
                };
            } else {
                setPcs(manufacturingStockPcs);
                setSankbar(true);
                setSankbarText("Please do not fill more then stock pcs!");
                setSankbarColor("warning");
            }
        } else if (name === 'weight') {
            if (manufacturingStockWeight >= value) {
                setWeight(value);
                if (value) {
                    setWeightValid(false);
                } else {
                    setWeightValid(true);
                };
            } else {
                setWeight(manufacturingStockWeight);
                setSankbar(true);
                setSankbarText("Please do not fill more then stock weight!");
                setSankbarColor("warning");
            }

        };
    };

    const partyStockData = (value) => {
        Post("MfgIss/PartyStock", {
            PartyID: value ? value.ParID : 0
        }).then((res) => {
            if (res) {
                if (res?.status === 1) {
                    if (res?.UserData.length > 0) {
                        setManufacturingStockArray(res?.UserData);
                    } else {
                        setLoader(false);
                        setSankbar(true);
                        setSankbarText(res?.message);
                        setSankbarColor("warning");
                    }
                } else {
                    setLoader(false);
                    setSankbar(true);
                    setSankbarText(res.message);
                    setSankbarColor("error");
                }
            }
        });
    };

    const onSaveManufacturing = () => {
        if (isInsert) {
            if (manufacturingArray.length > 0) {
                for (let c = 0; c < manufacturingArray.length; c++) {
                    if (manufacturingArray[c].I_Weight) {
                        manufacturingArray[c].I_Weight = manufacturingArray[c].I_Weight.split(" ")[0];
                    };
                };
            }

            const data = {
                MfgID: manufacturingID,
                OrderID: manufacturingOrderID,
                OrderDetID: manufacturingOrderDetID,
                ItemId: itemValue ? typeof itemValue === 'object' ? itemValue.ItemId : 0 : 0,
                PartyID: partyValue ? typeof partyValue === 'object' ? partyValue.ParID : 0 : 0,
                isDefault: photolink ? 0 : 1,
                I_Img: photoNo,
                SubData: manufacturingArray
            };

            const formData = new FormData();
            formData.append("image", Object.keys(bineryFile).length > 0 ? bineryFile : photolink);
            formData.append("JsonData", JSON.stringify(data));

            Post("MfgIss/IssueSave", formData).then((res) => {
                if (res) {
                    if (res.status === 1) {
                        setBineryFile({});
                        setPhotolink('');
                        setPhotoNo('');
                        setManufacturingArray([]);
                        setManufacturingStockArray([]);
                        setManufacturingStockSelected(null);
                        setManufacturingStockSelectedPcs(0);
                        setManufacturingStockSelectedWeight(0);
                        setPartyData([]);
                        setPartyValue(null);
                        setPartyValueValid(false);
                        setItemValue(null);
                        setItemValueVaild(false);
                        setItemOption([]);
                        setOrderValue(null);
                        setOrderValueVaild(false);
                        setOrderOption([]);
                        setMaterialValue(null);
                        setMaterialValueVaild(false);
                        setMaterialOption([]);
                        setPcs("");
                        setPcsValid(false);
                        setWeight("");
                        setWeightValid(false);
                        setRefreshKey(0);
                        setManufacturingStockPcs(0);
                        setManufacturingStockWeight(0);
                        setManufacturingID(0);
                        setSankbar(true);
                        setSankbarText(res?.message);
                        setSankbarColor("success");
                        setRefreshKey(prevKey => prevKey + 1);
                    } else {
                        setLoader(false);
                        setSankbar(true);
                        setSankbarText(res?.message);
                        setSankbarColor("error");
                    }
                }
            });
        } else {
            setSankbar(true);
            setSankbarText('You are not allowed!');
            setSankbarColor("warning");
        }
    };

    const checkDataSave = () => {
        if (manufacturingArray.length > 0) {
            if (!partyValue || typeof partyValue !== 'object') {
                setPartyValueValid(true);
                setSankbar(true);
                setSankbarText("Please select party!");
                setSankbarColor("warning");
                return;
            } else {
                checkpassword(true);
                setSingleData([]);
            }
        } else {
            if (!partyValue || typeof partyValue !== 'object') {
                setPartyValueValid(true);
                setSankbar(true);
                setSankbarText("Please select party!");
                setSankbarColor("warning");
            } else if (!materialValue || typeof materialValue !== 'object') {
                setMaterialValueVaild(true);
                setSankbar(true);
                setSankbarText("Please select material!");
                setSankbarColor("warning");
            } else if (!pcs) {
                setPcsValid(true);
                setSankbar(true);
                setSankbarText("Please select pcs!");
                setSankbarColor("warning");
            } else if (!weight) {
                setWeightValid(true);
                setSankbar(true);
                setSankbarText("Please select weight!");
                setSankbarColor("warning");
            } else {
                checkpassword(true);
                setSingleData([]);
            };
        };
    };

    const footerTemplate = () => {
        return (
            <div className='footer'>
                <Button label='Save' onClick={() => checkDataSave()} />
            </div>
        );
    };

    return (
        <div id='manufacturing' key={refreshKey}>
            <div className='masterform'>
                <div className='inputFilter'>
                    <div className='input'>
                        <SelectDropdown
                            onFocus={() => partyselection()}
                            changeEvent={(value, name) => onHandelchange(value, name)}
                            value={partyValue}
                            className={partyValueValid}
                            name={"Select Party*"}
                            id={"partytype"}
                            options={partyData}
                            optionsName={'FullName'}
                            icon={<svg className="icon" aria-hidden="true"><use xlinkHref="#party_name"></use></svg>}
                            autoFocus={true}
                            tabIndex={1}
                        />
                    </div>
                    <div className='input'>
                        <SelectDropdown
                            onFocus={() => orderselection()}
                            changeEvent={(value, name) => onHandelchange(value, name)}
                            value={orderValue}
                            className={orderValueVaild}
                            name={"Select order"}
                            id={"order"}
                            options={orderOption}
                            optionsName={'OrderNo'}
                            itemTemplate={itemTemplate}
                            tabIndex={2}
                            // itemSize={90}
                        />
                    </div>

                </div>
                <div className='inputFilter'>

                    <div className='input'>
                        <SelectDropdown
                            onFocus={() => itemselection()}
                            changeEvent={(value, name) => onHandelchange(value, name)}
                            value={itemValue}
                            className={itemValueVaild}
                            name={"Select item"}
                            id={"item"}
                            options={itemOption}
                            optionsName={'ItemName'}
                            tabIndex={3}
                        />
                    </div>


                    {typeof partyValue === 'object' ?
                        <div className='imageupload'>
                            <ImageUpload
                                setBineryFile={(value) => setBineryFile(value)}
                                photo={photolink}
                                setPhotolink={(value) => setPhotolink(value)}
                                Default={false}
                                tabIndex={4}
                            />
                        </div> : ""}
                </div>
            </div>
            <div className='manufacturingMain'>
                <div className='manufacturingIssue'>
                    <TableGrid
                        tableArray={[{
                            MaterialName: <div className=''><SelectDropdown
                                onFocus={() => materialselection()}
                                changeEvent={(value, name) => onHandelchange(value, name)}
                                className={materialValueVaild}
                                value={materialValue}
                                name={"Select Material*"}
                                id={"material"}
                                options={materialOption}
                                optionsName={'MaterialName'}
                                tabIndex={5}
                                autoFocus={manufacturingArray.length !== 0 ? true : false}
                            />
                                <div className=''>Vendor stock :-</div>
                                <div className=''>Empoyolee stock :-</div>
                            </div>,
                            MaterialId: 0,
                            I_Pcs: <div className=''>
                                <InputTypeNumber
                                    changeEvent={(value, name) => onHandelchange(value, name)}
                                    value={pcs}
                                    className={pcsValid}
                                    name={"Pcs*"}
                                    id={"pcs"}
                                    min={0}
                                    useGrouping={false}
                                    tabIndex={6}
                                />
                                <div className=''>{manufacturingStockPcs}</div>
                                <div className=''>{manufacturingStockSelectedPcs}</div>
                            </div>,
                            I_Weight: <div className=''>
                                <InputTypeNumber
                                    changeEvent={(value, name) => onHandelchange(value, name)}
                                    value={weight}
                                    className={weightValid}
                                    name={"Weight*"}
                                    id={"weight"}
                                    min={0}
                                    useGrouping={false}
                                    suffix=" gm"
                                    minFractionDigits={2}
                                    tabIndex={7}
                                />
                                <div className=''>{manufacturingStockWeight + " gm"}</div>
                                <div className=''>{manufacturingStockSelectedWeight + " gm"}</div>
                            </div>,
                            button: true
                        }, ...manufacturingArray]}
                        columns={manufacturingColumns}
                        // header={manufacturingEntryHeader}
                        actionButton={buttonTemplate}
                        pagination={true}
                        footer={true}
                        footerTemplate={footerTemplate}
                        onRowDoubleClick={(data) => rowDoubleClick(data)}
                    />
                </div>

                <div className='manufacturingView'>
                    <TableGrid
                        tableArray={manufacturingStockArray}
                        columns={manufacturingStockColumns}
                        pagination={true}
                        selectedData={manufacturingStockSelected}
                        selectionMode={"single"}
                    />
                </div>
            </div>

            {loader ? <Loader /> : ""}

            <PasswordBox
                open={verifyPassword}
                password={getPassword}
                verifyPassword={(value) => setVerifyPassword(value)}
                buttonCheck={() => { isDeteleUpdate ? materialDelete() : onSaveManufacturing() }}
            />

            <SnackbarNotification
                open={sankbar}
                text={sankbarText}
                color={sankbarColor}
                sankbar={() => setSankbar()}
            />

            <ConfirmDialogModal
                open={confirmModel}
                onHide={(value) => setConfirmModel(value)}
                onclick={() => { isDeteleUpdate && checkpassword(true) }}
                massege={confirmText}
            />
        </div>
    )
}

export default ManufacturingIssueEntry;