import React, { useEffect, useState } from 'react';
import "./PasswordBox.scss"
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import SnackbarNotification from '../Snackbar/SnackbarNotification';
import InputTypeText from '../InputTypeText/InputTypeText';
import { useNavigate } from 'react-router-dom';

function PasswordBox(props) {
  

    const [password, setPassword] = useState('');

    // Sank Bar
    const [sankbar, setSankbar] = useState(false);
    const [sankbarText, setSankbarText] = useState("");
    const [sankbarColor, setSankbarColor] = useState("");

    useEffect(() => {
        setPassword('');
    }, [props.open]);

    const verify = () => {
        if (password !== "" && password !== null) {
            if (password === props.password) {
                props.verifyPassword(false);
                props.buttonCheck();
            } else {
                setSankbar(true);
                setSankbarText("This password not match!");
                setSankbarColor("error");
            }
        } else {
            setSankbar(true);
            setSankbarText("Please fill password");
            setSankbarColor("warning");
        }
    }

    const footerContent = (<Button label="Verify" onClick={() => verify()} />);

    const onkeydown = (e) => {
        if (e.key === "Enter" || e.keyCode === 13) {
            verify();
        }
    }

    return (
        <Dialog className='Verify_Password' header="Verify Password" visible={props.open}   onHide={() => props.verifyPassword(false)} footer={footerContent}>
            <ul>
                <li className='Verify_Pass_li'>Please provide the password</li>
            </ul>
            <div className='from'>
                <div className='input'>
                    <InputTypeText keyfilter="" tabIndex={1} autoFocus={true} changeEvent={(value) => setPassword(value)} value={password} name={"Password"} id={"Password"} icon={false} keyDown={(e) => onkeydown(e)} />
                </div>
            </div>

            <SnackbarNotification open={sankbar} text={sankbarText} color={sankbarColor} sankbar={(value) => setSankbar(value)} />
        </Dialog>
    )
}

export default PasswordBox;