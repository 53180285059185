import React, { useEffect, useRef } from 'react';
import { Toast } from 'primereact/toast';

function SnackbarNotification(props) {
    const toast = useRef(null);
 
    useEffect(() => {
        if (props.open) {
            const severity = props.color;
            const color = capitalizeFirstLetter(props.color);
            const text = props.text;

            const customIcon = (
                <>
                    {severity === "success" ?
                        <svg className="icon" aria-hidden="true"><use xlinkHref="#success_toast"></use></svg>
                        : severity === "warning" ?
                            <svg className="icon" aria-hidden="true"><use xlinkHref="#warning_toast"></use></svg>
                            : severity === "error" ?
                                <svg className="icon" aria-hidden="true"><use xlinkHref="#error_toast"></use></svg> : ""}
                </>
            )
            toast.current.show({
                severity: severity,
                summary: color,
                detail: text,
                life: 3000,
                icon: customIcon,
                closable: true
                // onClose: () => props.sankbar(false)
            });
            props.sankbar(false);
        }
    }, [props.open, props.color, props.text]);

    function capitalizeFirstLetter(str) {
        return str.charAt(0).toUpperCase() + str.slice(1);
    }

    return (
    <Toast ref={toast} />
    
    );

}

export default SnackbarNotification; 