import { Post } from "../../commanAPI/commanAPI";

function ComboFill(type, tabletype, uniqueid) {
    return Post('CmbFill', { Type: type ? type : "", tblType: tabletype ? tabletype : "", UniqueId: uniqueid ? uniqueid : "" }).then((res) => {
        if (res) {
            return res;
        } else {
            return null;
        }
    });
}

export { ComboFill };