import { Button } from 'primereact/button'
import React, { useEffect } from 'react'
import page500 from "../../assets/Img/500_page.svg"
import { useNavigate } from 'react-router-dom';


function Page500() {

    const navigate = useNavigate();

    // useEffect(() => {
    //     sessionStorage.removeItem("header");
    //     localStorage.removeItem('userData');
    //     localStorage.removeItem("login");
    //     localStorage.removeItem("ID");
    // }, []);

    let GoToBack = () => {
        sessionStorage.removeItem("header");
        localStorage.removeItem('userData');
        localStorage.removeItem("login");
        localStorage.removeItem("ID");
        navigate('/login');
    }
    return (
        <div>
            <div className='page404'>
                <div className='page404-img'>
                    <img src={page500}></img>
                </div>
                <div className='page404-text'>
                    <p>Oops...</p>
                    <p>This is wrong. This page does no longer exist, or it never had.</p>
                    <Button className='page404-btn' onClick={() => GoToBack()}>GO BACK HOME</Button>

                </div>
            </div>
        </div>
    )
}

export default Page500;