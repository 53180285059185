const PathConstants = {
    Page500: "500",
    Login: "/",
    LoginPage: "login",
    Dashboard: "dashboard",
    Profile: "profile",
    Master: "master/master",
    Party: "master/party",
    Newstock: "purchase/newstock",
    Oldstock: "purchase/oldstock",
    Rawstock: "purchase/rawstock",
    Newuser: "user/newuser",
    Userpermission: "user/Userpermission",
    Reportpermission: "user/reportpermission",
    Usergroup: "user/usergroup",
    Settingpolicy: "setting&policy",
    Sales_crm: "salescrm/salescrm",
    Display: "salescrm/display",
    Order: "salescrm/order",
    Issue: "salescrm/issue",
    Report: "report",
    Icon: "icon",
    Favorite: "favorite",
    Profile: "profile",
    Cart: "Cart/Cart",
    Checkout: "Cart/Checkout",
    OrderSuccess: "Cart/OrderSuccess",
    OrderCancel: "Cart/OrderCancel",
    MfgIssue: "manufacturing/manufacturingissue",
    MfgRecive: "manufacturing/manufacturingreceive",
    Demo: "demo",
    Product: "salescrm/display",
    Accounting: "Accounting",
    Demo: "demo",
};

export default PathConstants;