import React, { useState, useRef, useEffect } from 'react';
import './SelectDropdown.scss';
import { AutoComplete } from 'primereact/autocomplete';

function SelectDropdown(props) {
    const value = props.value;
    const options = props.options;
    const optionsName = props.optionsName;
    const id = props.id;
    const name = props.name;
    const icon = props.icon;
    const className = props.className;
    const read = props.read;
    const tabIndex = props.tabIndex;
    const autoFocus = props.autoFocus;
    const disabled = props.disabled;
    const itemSize = props.itemSize;


    const [data, setData] = useState([]);
    const [filtereddata, setFiltereddata] = useState(null);
    const inputRef = useRef(null);


    useEffect(() => {
        setData(options);
        if (className) {
            inputRef.current.focus();
        }
    }, [options, className]);

    const search = (event) => {
        setTimeout(() => {
            let _filtereddata;
            if (!event.query.trim().length) {
                _filtereddata = [...data];
            } else {
                _filtereddata = data.filter((dataItem) => {
                    for (const key in dataItem) {
                        if (dataItem.hasOwnProperty(key) && typeof dataItem[key] === 'string') {
                            if (dataItem[key].toLowerCase().includes(event.query.toLowerCase())) {
                                return true;
                            }
                        }
                    }
                    return false;
                });
            }
            setFiltereddata(_filtereddata);
        }, 20);
    }

    var entercount = 0;
    const handleKeyDown = (e) => {
        if (e.key === "Enter") {
            if (typeof value !== 'object') {
                entercount = entercount + 1;
                if (entercount === 2) {
                    entercount = 0;
                    if (props.onDoubleClick() !== undefined) {
                        props.onDoubleClick();
                        entercount = 0;
                    }
                }
                setTimeout(() => {
                    entercount = 0;
                }, 1000);
            }
        }
    };

    return (
        <div className={!icon ? "selction" : "p-inputgroup"}>
            {!icon ? "" :
                <span className={className ? `p-inputgroup-addon p-invalid ${disabled ? "disabled" : ""}` : `p-inputgroup-addon ${disabled ? "disabled" : ""}`}>
                    {icon}
                </span>
            }
            <span className={`p-float-label ${disabled ? "disabled" : ""}`} onKeyDown={handleKeyDown}>
                <AutoComplete
                    inputRef={inputRef}
                    onClick={() => props.onFocus ? props.onFocus() : {}}
                    onClear={() => props.onFocus ? props.onFocus() : {}}
                    onDropdownClick={() => props.onFocus ? props.onFocus() : {}}
                    value={value}
                    className={className ? "p-invalid" : ""}
                    field={optionsName}
                    id={id}
                    suggestions={filtereddata}
                    completeMethod={search}
                    onChange={(e) => {
                        if (typeof props.changeEvent === 'function') {
                            props.changeEvent(e.target.value, id);
                        }
                    }}
                    dropdown
                    virtualScrollerOptions={{ itemSize: itemSize ? itemSize : 41 }}
                    // scrollHeight={{ height: 200 }}
                    dropdownAutoFocus={false}
                    autoFocus={autoFocus}
                    tabIndex={tabIndex}
                    readOnly={read}
                    disabled={disabled}
                    onDoubleClick={() => !disabled && !read ? props.onDoubleClick !== undefined ? props.onDoubleClick() : {} : {}}
                    itemTemplate={props.itemTemplate ? props.itemTemplate : false}
                // style={{ maxHeight: '200px', height: 'auto' }}
                />
                {name ? <label htmlFor={id} className={className ? "p-invalid" : ""}>{name}</label> : ""}
            </span>

        </div>
    );
}

export default SelectDropdown;